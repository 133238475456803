import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../navs/Header';
import Footer from '../../navs/Footer';
import Functions from '../../../utils/Functions';
import { serverEndPoint, webTitle, sellerCookie} from '../../../utils/Helpers';

let formData = new FormData();
    
export default function AddProductPictures(props) {
    
    let [values, setValues] = React.useState({
        product: props.match.params.product,
        details: [],
        productLoaded: '',
    });
    
    async function onChangePhotoHandler(event) {        
        const files = event.target.files;
        
        for(let i = 0; i < event.target.files.length; i++) {
            formData.append(`photos[]`, files[i]);
        }
    }

    async function uploadPictures(event) {
        event.preventDefault();
        const status = document.getElementById('upload-picture-status');
        const btn = document.getElementById('upload-picture-btn');
        
        formData.append('product_id', values.product);
        Functions.disableBtn(btn);
            
        await axios({
            method: 'post',
            url: `${serverEndPoint}sellers/product/pictures/upload`,
            data: formData,
            headers : {
                'Content-Type': 'application/form-data',
                'Accept' : 'application/json',
                'x-access-token' : sellerCookie
            }
        })
        .then(function (response) {
            Functions.enableBtn('Upload', btn);

            if(response.status == 201) {
                status.innerHTML = `<p style='color:green'>Pictures uploaded.</p>`;
                window.location = `/sellers/ads/details/${values.product}`;
            } else {
                Functions.enableBtn('Upload', btn);
                status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
                setTimeout(function() {
                    window.location.reload();
                }, 1000);
            }
        })
        .catch(function (response) {
            Functions.enableBtn('Upload', btn);
            status.innerHTML = `<p style='color:red'>Pictures not uploaded. Try again</p>`;
            setTimeout(function() {
                window.location.reload();
            }, 1000);
        });    
    }

    async function getProductDetails() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/details/${values.product}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200 && details) {  
				setValues( values => ({
					...values, 
                    details: details,
                    productLoaded: true,
                }));
            } else {
                window.location="/404";
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }

    useEffect( () => {
        getProductDetails(); 
        document.title = webTitle+" Add Ads Pictures";
    }, []);
    
    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}}>
				<div className="container">
					<div className="title-content">
						<h1>
                            {!values.productLoaded ? 
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
                                <div> 
                                    Add New Pictures to {Functions.capitalizeWord(values.details.name)}
                                </div>
                            }
                        </h1>
					</div>
				</div> <br/><br/>
			</section>
        
            <section className="padd-0">
				<div className="container">
					<div className="add-listing-box translateY-60 edit-info mrg-bot-25 padd-bot-30 padd-top-25">
						
                        <form method="post" action="#" onSubmit={event => uploadPictures(event)}>
                            <div className="row mrg-r-10 mrg-l-10">
                                <div className="col-sm-12">
                                    <label>Select pictures</label>
                                    <input type='file' id="files" accept='.jpg, .png. .jpg, .PNG, .JPG, .JPEG' onChange={onChangePhotoHandler} name="photos" multiple/>
                                    <div id="all-files"></div>
                                </div>
                            </div> <br/>
                            <div className="text-center">
                                <button type="submit" id="upload-picture-btn" className="btn btn-warning"><i className="fa fa-upload"></i> Upload </button>
                                <br/><br/>
                                <div id="upload-picture-status"></div>
                            </div>
                        </form>
					</div>
				</div>
			</section>
			
            <Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
