import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle, customerCookie } from '../../utils/Helpers';

export default function CustomerHooks() {
    
    let [data, setData] = React.useState({
        firstname: '', 
        lastname: '',
        email: '',
        phone: '',
        picture: '',
        about: '',
        address: '',
        state: '',
        city: '',
        institution: '',
        institution_name: '',
        dob: '',
    });

    async function getCustomerDetails() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+'customers/profile',
            headers : {
                'Content-Type': 'application/json',
                'x-access-token' : customerCookie
            }
        })
        .then( async (response) => {
            
            if(response.status) {
                let details = await response.data.data;
                
                setData( data => ({
                    ...data,
                    firstname : details.firstname,
                    lastname : details.lastname,
                    email : details.email,
                    phone : details.phone,
                    institution : details.institution_id,
                    institution_name : details.institution_name,
                    dob : details.dob,
                    state : details.state,
                    city : details.city,
                    address : details.address,
                    about : details.about,
                    picture: details.picture_url,
                    profileLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            //window.location = "/login";
        });
    }

    useEffect( () => {
        getCustomerDetails();
    }, []);
    
    return data;
}
