import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Header from '../../navs/Header';
import Footer from '../../navs/Footer';
import Functions from '../../../utils/Functions';
import { serverEndPoint, webTitle, sellerCookie} from '../../../utils/Helpers';

let formData = new FormData();
let productPicture;
    
export default function AddProduct() {
    
    let [ad_types, setAdTypes] = React.useState(['goods', 'services']);
    let [pricing_types, setPricingTypes] = React.useState(['fixed', 'varies']);

    let [values, setValues] = React.useState({
        category_id: '',
        name: '', 
        type: '',
        pricing_type: '',
        fixed_price: '',
        varying_price: '',
        description: '',
        categories: [],
        categoryLoaded: '',
    });
    
    function onChangeHandler(event) {
        const { name, value } = event.target;
        
        if(name == 'pricing_type') {
            
            setValues(values => ({...values, [name] : value}));
            
            if(value == 'fixed') {
            
                document.getElementById('fixed_price_div').style.display = 'block';
                document.getElementById('varying_price_div').style.display = 'none';
                document.getElementById('varying_price').value = '';
            
            } else if(value == 'varies') {
            
                document.getElementById('fixed_price_div').style.display = 'none';
                document.getElementById('varying_price_div').style.display = 'block';
                document.getElementById('fixed_price').value = '';
            
            } else {
            
                document.getElementById('fixed_price_div').style.display = 'none';
                document.getElementById('varying_price_div').style.display = 'none';
                document.getElementById('fixed_price').value = '';
                document.getElementById('varying_price').value = '';
            
            }

        } else {
            
            if(name == 'fixed_price') {
                if(Functions.isInt(value)) {
                    setValues(values => ({
                        ...values, 
                        fixed_price : value,
                        varying_price: '',
                    }));
                } else {
                    document.getElementById('fixed_price').value = '';
                    setValues(values => ({
                        ...values, 
                        fixed_price : '',
                        varying_price: '',
                    }));
                }
            } else if(name == 'varying_price') {
                setValues(values => ({
                    ...values, 
                    fixed_price : '',
                    varying_price: value,
                }));
            } else {
                setValues(values => ({...values, [name] : value}));
            }
        }
    }

    async function getCategories() {
        
        const getModalCookie = Functions.getCookie('dont-show-selling-tips-modal');

        if(!getModalCookie) {
            setTimeout(function () {
                document.getElementById('sellingTipModal').click();
            }, 1000);
        }
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/categories`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200) {  
				setValues( values => ({
					...values, 
					categories: details,
                    categoryLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }

    async function selectProductPicture(event) {        
        const files = event.target.files;
        formData.append(`picture`, files[0]);
        
        for(let value of formData.entries()) {
            if(value[1]) {
                productPicture = value[1];
            }
        }
    }

    async function uploadProductPicture(product_id) {        
        
        await axios({
            method: 'post',
            url: `${serverEndPoint}sellers/product/picture/upload-single/${product_id}`,
            data: formData,
            headers : {
                'Content-Type': 'application/form-data',
                'Accept' : 'application/json',
                'x-access-token' : sellerCookie
            }
        });
    }

    async function deleteProduct(product_id) {
        await axios({
            method: 'delete',
            url: serverEndPoint+`sellers/product/delete/${product_id}`,
            headers : {
                'Content-Type' : 'application/json',
                'x-access-token' : sellerCookie,
            }
        });
    }
    
    async function addProduct(event) {
        event.preventDefault();
        const status = document.getElementById('add-product-status');
        const btn = document.getElementById('add-product-btn');
        
        const { category_id, name, pricing_type, fixed_price, varying_price, type, description } = values;  
        const allValues = [category_id, name, pricing_type, type, description];
        const isValueEmpty = Functions.isEmpty(allValues);

        let actual_price;

        if(pricing_type == 'fixed' && fixed_price != '') {
            if(Functions.isInt(fixed_price)) {
                actual_price = fixed_price;
            } else {
                actual_price = '';
            }
        } else if(pricing_type == 'varies' && varying_price != ''){
            actual_price = varying_price;
        } else {
            actual_price = '';
        }

        if(!productPicture || productPicture == '')  {
            status.innerHTML = "<p style='color:red'> Kindly select a picture for your ad. </p>";
            return false;
        } else if(isValueEmpty) {
            status.innerHTML = "<p style='color:red'> Fill all fields </p>";
            return false;
        } else if(actual_price == '') {
            status.innerHTML = "<p style='color:red'> Enter the price </p>";
            return false;
        } else {
                
            Functions.disableBtn(btn);
            status.innerHTML = "<p style='color:black'> Adding new ads ... </p>";
            
            const data = `category_id=${category_id}&name=${name}&price=${actual_price}&type=${type}&description=${description}`; 
            
            await axios({
                method: 'post',
                url: `${serverEndPoint}sellers/product/add`,
                data: data,
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept' : 'application/json',
                    'x-access-token' : sellerCookie
                }
            })
            .then( async (response) => {
                const details = response.data.data;
                            
                if(response.status == 201) {
                    
                    if(productPicture)  {
                        await uploadProductPicture(details.id)
                        .then( async () => {
                            status.innerHTML = `<p style='color:green'>Your ads was successfully posted.</p>`;
                            setTimeout( () => {
                                window.location = `/sellers/ads/add/pictures/${details.id}`;
                            }, 3000);
                        })
                        .catch( async (error) => {
                            await deleteProduct(details.id);
                            status.innerHTML = "<p style='color:red'>Ads not added. Try again. </p>";
                        });
                    } else {
                        await deleteProduct(details.id);
                        status.innerHTML = "<p style='color:red'>Ads not added. Try again. </p>";
                    }
                } else {
                    status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
                }

                Functions.enableBtn('Add', btn);
                
            }).
            catch( (error) => {
                Functions.enableBtn('Add', btn);
                status.innerHTML = "<p style='color:red'>Ads not added. Try again. </p>";
            });
        }   
    }

    async function dontShowModal() {
		Functions.setCookie('dont-show-selling-tips-modal', 'yes', 100);
		document.getElementById('close-modal-btn').click();
	}

    useEffect( () => {
        getCategories();
        document.title = webTitle+" Add New Ads";
    }, []);
    
    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}}>
				<div className="container">
					<div className="title-content">
						<h1>Add New Ads</h1>
					</div>
				</div> <br/><br/>
			</section>
        
            <section className="padd-0">
				<div className="container">
					<div className="add-listing-box translateY-60 edit-info mrg-bot-25 padd-bot-30 padd-top-25">
						
                        <form method="post" action="#" onSubmit={event => addProduct(event)}>
                            <div className="">
                                <div className="col-sm-6">
                                    <label> Select a picture for your ad </label>
                                    <input type="file" name="picture" accept='.jpg, .png. .jpg, .PNG, .JPG, .JPEG' onChange={selectProductPicture}/>
                                    <div id="upload-picture-status"></div>  <br/>
                                </div>

                                <div className="col-sm-6">
                                    <label>Category</label>
                                    {values.categoryLoaded ?
                                        <div>
                                            <select className="form-control" name="category_id" onChange={onChangeHandler}>
                                                <option value=""> -- Select ads category -- </option>
                                                    {values.categories.map( (category, index) => 
                                                        <option key={index} value={category.id}>
                                                            {Functions.capitalizeWord(category.name)} ({category.type})
                                                        </option>
                                                    ) 
                                                    }
                                            </select><br/>
                                        </div> :  
                                        <div> <br/>
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>										
                                    }
                                </div> 

                                <div className="col-sm-6">
                                    <label>Type</label>
                                    <select className="form-control" name="type" onChange={onChangeHandler}>
                                        <option value=""> -- Select ads type -- </option>
                                        {ad_types.map( (ad_type, ad_index) => 
                                            <option key={ad_index} value={ad_type}>
                                                {Functions.capitalizeWord(ad_type)}
                                            </option>
                                        ) 
                                        }
                                    </select> <br/>  
                                </div>  

                                <div className="col-sm-6">
                                    <label>Title </label>
                                    <input type="text" className="form-control" name="name" onChange={onChangeHandler} value={values.name}/> <br/>
                                </div>
                                
                                <div className="col-sm-6">
                                    <label>Pricing Type</label>
                                    <select className="form-control" name="pricing_type" onChange={onChangeHandler}>
                                        <option value=""> -- Select pricing type -- </option>
                                        {pricing_types.map( (pricing_type, pricing_index) => 
                                            <option key={pricing_index} value={pricing_type}>
                                                {Functions.capitalizeWord(pricing_type)}
                                            </option>
                                        ) 
                                        }
                                    </select> <br/>  
                                </div>  
                                
                                <div className="col-sm-6">
                                    
                                    {values.pricing_type != '' ? 
                                        <label>Price </label> : null
                                    }
                                        
                                    <div style={{display: 'none'}} id="fixed_price_div">
                                        <input type="number" className="form-control" name="fixed_price" id="fixed_price" onChange={onChangeHandler} onKeyPress={Functions.isCharNumber} inputmode="numeric" pattern="[0-9]*" maxLength="15"/>
                                    </div>
                                    
                                    <div id="varying_price_div" style={{display: 'none'}} >
                                        <input type="text" className="form-control" name="varying_price" id="varying_price" onChange={onChangeHandler}  maxLength="15"/> <br/>
                                    </div>
    
                                </div>
                                
                                <div className="col-sm-12 col-xs-12 col-md-12">
                                    <label>Description</label>
                                    <textarea className="form-control" rows="10" name="description" onChange={onChangeHandler} value={values.description}></textarea>
                                </div>
                            
                            </div>

                            <div style={{clear: 'both'}}></div>
                            <br/>

                            <div className="text-center">
                                <button type="submit" id="add-product-btn" className="btn btn-warning"><i className="fa fa-plus"></i> Add </button>
                                <br/><br/>
                                <div id="add-product-status"></div>
                            </div>
                        </form>
					</div>
				</div>
			</section>
			
            <Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>

            <button style={{display: 'none'}} type="button" id="sellingTipModal" className="btn btn-primary" data-toggle="modal" data-target="#sellingTipsModal"> Safety Tips </button>

			<div class="modal fade" style={{marginTop:100}} id="sellingTipsModal" tabindex="-1" role="dialog" aria-labelledby="sellingTipsModalTitle" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title" id="safetyTipsModalLongTitle">Selling tips for sellers</h4>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<ul>
								<li> You can get your store link which can be posted to various social media at your <a href ="/sellers/profile"><b>profile page</b></a>. </li>
								<li> Ensure you post relevant and appropriate pictures. </li>
								<li> Ensure you post clear and quality pictures. </li> 
								<li> Ensure you have a complete profile so you look professional . </li>
								<li> Ensure you post your ads in the correct category so as to reach out to the right customers. </li>
								<li> Ensure you have a clear and detailed description about your products and services. </li>
                                <li> Avoid over-pricing your goods and services. </li>
							</ul>
						</div>
						<div class="modal-footer">
                            <button type="button" class="btn btn-info" onClick={dontShowModal}>Don't show this again</button>
							<button type="button" class="btn btn-secondary" id="close-modal-btn" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
			
        </div>
    );
}
