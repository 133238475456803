import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../navs/Header';
import Footer from '../navs/Footer';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle } from '../../utils/Helpers';
import TheProduct from './TheProduct';

export default function SellerDetails(props) {
	
	const history = useHistory();
    
	let [values, setValues] = React.useState({
		profileLoaded: false, 
		productLoaded: false,
        reviewLoaded: false,
        details: [],
        reviews: [],
		products: [],
		business_url: props.match.params.business_url,
	    count_reviews: 0,
		rating: 0,
		total_pages: 0,
        total_products: 0,
        current_page: 1,
        prev_page: 1,
        next_page: 1
	});
	
	async function prevItem() {
        window.scrollTo(0,0);
        
        let prev_page = values.current_page-=1;
        
        setValues(values => ({
            ...values, 
            current_page: prev_page,
            productLoaded: false,
        }));

        await getSellerProducts();
    }

    async function nextItem() {

        window.scrollTo(0,0);
        
        let prev_page = values.current_page;
        let next_page = values.current_page+=1;
        
        setValues(values => ({
            ...values, 
            current_page: next_page,
            prev_page: prev_page,
            productLoaded: false,
        }));

        await getSellerProducts();
    }
	
	async function getSellerDetails() {
		
		await getSellerId()
		.then( async (seller_id) => {
			
			await axios({
				method: 'get',
				url: serverEndPoint+`seller/details/${seller_id}`,
				headers : {
					'Content-Type' : 'application/json',
					'Accept' : 'application/json',
				}
			})
			.then( async (response) => {
				let details = response.data.data;
				
				if(response.status == 200 && details) {  
					setValues( values => ({
						...values, 
						details: details,
						profileLoaded: true,
					}));
				} else {
					//history.push('/404');
				}
			}).
			catch( (error) => {
				//history.push('/404');
			});
		})
		.catch( (error) => {
			//history.push('/404');
		});
	}
	
	async function getSellerId() {
		
		return new Promise( async (resolve, reject) => {
			await axios({
				method: 'get',
				url: serverEndPoint+`seller/url/${values.business_url}`,
				headers : {
					'Content-Type' : 'application/json',
					'Accept' : 'application/json',
				}
			})
			.then( async (response) => {
				
				const details = response.data.data;
				
				if(response.status == 200 && details) {  
					resolve(details.id);
				}
			}).
			catch( (error) => {
				reject('error');
			});
		});
    }
    
    async function getSellerReviews() {
        
        await getSellerId()
		.then( async (seller_id) => {
			await axios({
				method: 'get',
				url: serverEndPoint+`seller/reviews/${seller_id}`,
				headers : {
					'Content-Type' : 'application/json',
					'Accept' : 'application/json',
				}
			})
			.then( async (response) => {
				const reviews = response.data.data.reviews;
				const count_reviews = response.data.data.count_reviews;
				const rating = response.data.data.rating;
				console.log(reviews);

				if(response.status == 200) {  
					setValues( values => ({
						...values,
						reviews: reviews,
						count_reviews: count_reviews,
						rating: rating,
						reviewLoaded: true,
					}));
				}
			})
			.catch( (error) => {
				console.log(error);
			});
		})
		.catch( (error) => {
			console.log(error);
		});
	}
	
	async function getSellerProducts() {
        
        await getSellerId()
		.then( async (seller_id) => {
			await axios({
				method: 'get',
				url: serverEndPoint+`seller/${seller_id}/${values.current_page}`,
				headers : {
					'Content-Type' : 'application/json',
					'Accept' : 'application/json',
				}
			})
			.then( async (response) => {
				const products = response.data.data.seller_products;
				const total_products = response.data.data.count_products;
				const total_pages = response.data.data.total_pages;

				if(response.status == 200) {  
					setValues( values => ({
						...values, 
						total_products: total_products,
						total_pages: total_pages,
						products: products,
						productLoaded: true,
					}));
				}
			})
			.catch( (error) => {
				console.log(error);
			});
		})
		.catch( (error) => {
			console.log(error);
		});
	}
    
    useEffect( () => {
		getSellerDetails();
		getSellerProducts();
		getSellerReviews();
		document.title = webTitle+" Seller's Profile";
    }, []);

    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <section className="title-transparent page-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}}>
				<div className="container">
					<div className="title-content">
						<div className="main-author-header">

							{!values.details.picture_url || values.details.picture_url == null ?
								
								<div className="mah-img-box">
									<img src={window.location.origin + '/assets/img/avatar.png'} className="img-responsive img-circle" alt={values.details.business}/>
								</div> :

								<div className="mah-img-box">
									<a data-fancybox="gallery" href={values.details.picture_url}>
										<img src={values.details.picture_url} className="user-img" alt={values.details.business}/>
									</a>
								</div>
							}

							{/* <div className="mah-img-box">
								{!values.details.picture_url ?
									<img src="../../assets/img/avatar.png" className="img-responsive img-circle" alt=""/> :
									<img src={values.details.picture_url} className="user-img" alt=""/>
								}
							</div> */}
							<div className="mah-detail-box">
								{!values.profileLoaded ? 
									<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
									<div> 
										<h4>
											{Functions.capitalizeWord(values.details.firstname)} {Functions.capitalizeWord(values.details.lastname)} &nbsp; ({values.details.business})
										</h4>
										<small>{values.details.institution_name}</small><p></p>
										<p>
											<span className="pull-left"> 
												<a href={`tel:${values.details.phone}`} style={{color: 'white'}}><i className="fa fa-phone"></i> Call </a>
											</span>

											<span className="pull-left" style={{marginLeft:50}}> 
												<a href={`sms:${values.details.phone}`} style={{color: 'white'}}><i className="fa fa-envelope"></i> SMS </a>
											</span>
										</p> 

										<div style={{clear:'both'}}></div><p></p>
                        	
										<div className="inside-rating buttons listing-rating theme-btn button-plain">
											<span className="value">
												{!values.reviewLoaded ? 
													<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>:
													<span><i className="fa fa-star"></i> {values.rating}</span>
												}
											</span>
										</div> 
										
										<br/> <br/>
										
										{!values.profileLoaded ? 
											<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
											
											<div align="left">
												<p className="pull-left"> {values.details.created_at} <br/> <small>Registered</small></p>

												<p className="pull-right"> {values.details.last_seen} <br/> <small>Last seen </small></p>

											</div>
										}
									</div> 
								}
							</div>
								
							{/* <div className="profile-cover-content">
								<div className="container">
									<div className="cover-buttons">
										<ul>
											<li>
												<div className="inside-rating buttons listing-rating theme-btn button-plain">
													<span className="value">
														<span><i className="fa fa-comment"></i> Start chat </span>
													</span>
												</div>
											</li>
											<li>
												<div className="inside-rating buttons listing-rating theme-btn button-plain">
													<span className="value">
														<span><i className="fa fa-comment"></i> Start chat </span>
													</span>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</section>

            <div className="clearfix"></div>
			
            <section className="padd-top-20">
				<div>
					<div>
						<div>
							<div className="container">
							<br/><br/>

								<h3 className="container"> Ads </h3>
								<br/>

								{!values.productLoaded?
									
									<div align="center" style={{margin:70}}>
										<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
									</div> :
									
									<div>
										
										{values.total_products == 0 ?
											
											<div align="center" style={{marginTop:10, fontSize:40, color: '#EA7602'}}>
												<i className="fa fa-bullhorn"></i> <br/><br/>
												No Ads Found
											</div> :
											
											<div>
												
												<h4 align="center"> {values.search_result} </h4>
												
												<ul>
													{values.products.map( (product, index) => 
														<TheProduct
															key = {index}
															from = 'ads'
															id = {product.id}
															name = {product.name}
															price = {product.price}
															picture = {product.picture}
															category = {product.category}
															seller_institution = {product.seller_institution}
															seller_phone = {product.seller_phone}
															added_on = {product.created_at}
															rating = {product.rating}
														/>
													)}
												</ul>

												<div align="center">
													<br/>
													
													<p style={{textTransform: 'none'}}> Page <b>{values.current_page}</b> of {values.total_pages} </p>

													{values.current_page > 1 ?
														<button onClick={prevItem.bind(this)} style={{borderRadius: 5}} className="btn btn-xs btn-info"><i className="fa fa-arrow-left"></i> Prev </button>
														: null
													}                                                                        
													&nbsp; &nbsp; 
													
													{values.current_page != values.total_pages ?
														<button onClick={nextItem.bind(this)} style={{borderRadius: 5}} className="btn btn-xs btn-warning"> Next <i className="fa fa-arrow-right"></i></button> : null
													}
												
												</div>
												
											</div>
										}
									</div>
								}

								<div style={{clear:'both'}}></div>

								<div className="detail-wrapper-header"  style={{marginTop:20}}>
									<h4>{!values.reviewLoaded ? 
											<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
											<div> {values.count_reviews} Reviews</div>
										}</h4>
								</div>
								
								<div className="detail-wrapper-body">
									
									{!values.reviewLoaded?
									
										<div align="center" style={{marginTop:10}}>
											<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
										</div> :
										
										<div>
											
											{values.reviews.length == 0 ?
												
												<div align="center" style={{fontSize:20, color: '#EA7602'}}> No reviews yet </div> :
												
												<div>
													<ul className="review-list">

														{values.reviews.map( (review, index) => 
															
															<li key={index}>
																<div className="reviews-box">
																	<div className="review-body">
																		<div className="review-avatar">
																			{!review.customer_picture ?
																				<img alt="" src="../../assets/img/avatar.png" className="avatar avatar-140 photo"/> :
																				<img src={review.customer_picture} className="user-img"/>
																			}
																		</div>
																		<div className="review-content">
																			<div className="review-info">
																				<div className="review-comment">
																					<div className="review-author" style={{fontSize: 13}}>
																						{review.customer_fullname} 
																					</div>
																					<div className="review-comment-stars">
																						<i className="fa fa-star"></i> {review.rating}
																					</div><p></p>
																				</div>
																				<div className="review-comment-date">
																					<div className="review-date">
																						<small>{review.created_at}</small>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<p style={{fontSize: 13, padding: 10}}>{review.comment}</p>
																	
																</div>
															</li>
														)}
													</ul>
										</div>
										}
									</div>
									}
									
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
