import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Header from './navs/Header';
import Footer from './navs/Footer';
import Functions from '../utils/Functions';
import { serverEndPoint, webTitle } from '../utils/Helpers';
import TheProduct from './products/TheProduct';

export default function Home() {
    
	let [goodCategories, setGoodCategories] = React.useState('');
	let [serviceCategories, setServiceCategories] = React.useState('');
	let [institutions, setInstitutions] = React.useState('');
	const history = useHistory();
	const screen_width = window.screen.width;
	
	const [values, setValues] = React.useState({
		products:[],
		screen_width: screen_width,
		goods_counter: 0,
		service_counter: 0,
		top_sellers: [],
		goodLoaded: false,
		showOtherGoods: false,
		serviceLoaded: false,
		showOtherServices: false,
		productLoaded: false,
		institutionsLoaded: false,
		topSellerLoaded: false,
		loaded: false,
		search: '',
		institution: '',
	});

	function onChangeHandler(event) {
        const { name, value } = event.target;
        setValues(values => ({...values, [name] : value}));
	}
	
	async function selectCategory(event) {
		const { name, value } = event.target;
        history.push(`/ads/category/${value}`);
	}
	
	async function getInstitutions() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`all-institutions`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            console.log(response);
            if(response.status == 200) {  
                setInstitutions(institutions => [...institutions, details]);
                setValues( values => ({
                    ...values, 
					institutionsLoaded: true,
				}));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
	}
	
	async function listGoodCategories() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/home-categories/goods`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
			
            if(response.status == 200) {  
                setGoodCategories(details);
                setValues( values => ({
                    ...values, 
                	goodLoaded: true,
                }));
            }
		})
		.catch( (error) => {
            console.log(error);
        });
	}

	async function listServiceCategories() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/home-categories/services`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200) {  
				setServiceCategories(details);
                setValues( values => ({
                    ...values, 
                    serviceLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
	}

	async function searchProduct(event) {
    
        event.preventDefault();
        const status = document.getElementById('search-status');
        const { search, institution } = values;
		const allValues = [institution];
		const isValueEmpty = Functions.isEmpty(allValues);
		
		if(isValueEmpty) {
            status.innerHTML = "<p style='color:red'> No match found </p>";
            return false;
        } else {
			history.push(`/ads/search?institution=${institution}&q=${search}`);
		}     
	}
	
	async function getLatestProducts() {
		
        await axios({
            method: 'get',
            url: serverEndPoint+`products/latest`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let products = response.data.data;
            
            if(response.status == 200) {  
				setValues( values => ({
                    ...values, 
                	products: products,
                    productLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
	}

	async function getTopSellers() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`seller/top`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let sellers = response.data.data;
			
            if(response.status == 200) {  
				setValues( values => ({
                    ...values, 
                	top_sellers: sellers,
                	topSellerLoaded: true,
				}));
				
				const getModalCookie = Functions.getCookie('dont-show-home-modal');

				if(!getModalCookie) {
					setTimeout(function () {
						document.getElementById('homeModal').click();
					}, 3000);
				}
            }
        }).
        catch( (error) => {
            console.log(error);
        });
	}

	async function seeMore(type) {
		
		if(type == 'goods') {
			setValues(values => ({
				...values,
				showOtherGoods: !values.showOtherGoods,
			}));
		} else if(type == 'services') {
			setValues(values => ({
				...values,
				showOtherServices: !values.showOtherServices,
			}));
		}
	}

	async function dontShowModal() {
		Functions.setCookie('dont-show-home-modal', 'yes', 100);
		document.getElementById('close-modal-btn').click();
	}

	useEffect( () => {
		getInstitutions();
		listGoodCategories();
		listServiceCategories();
		getLatestProducts();
		getTopSellers();
		document.title = webTitle+" Get Connected";
    }, []);
	
    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent home-page-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}}>
				<div className="container">
					<div className="title-content">
						<h3 style={{color:'#fff'}}>Buy and Sell Anything in Nigerian Tertiary Institutions</h3>
					</div>
					<form className="form-verticle" method="post" action="#" onSubmit={event => searchProduct(event)}>
						<div className="col-md-4 col-sm-4">
							{values.institutionsLoaded?
								<select className="form-control" name="institution" onChange={onChangeHandler}>
									<option value=""> -- Select institution -- </option>
									<option value="all"> All Institutions </option>
									{institutions[0].map( (institution, index) => 
										<option key={index} value={institution.id}>
											{institution.name}
										</option>
									)}
								</select> :
								<p style={{color: '#fff'}}> Please wait while institutions are retrieved... </p>
							}
						</div>
						<div className="col-md-6 col-sm-6">
							<input type="text" name="search" onChange={onChangeHandler} className="form-control" placeholder="What are you looking for?"/>
						</div>
						<div className="col-md-2 col-sm-2">
							<button type="submit" id="search-btn" className="btn theme-btn normal-height full-width"><i className="fa fa-search"></i> Search</button>
						</div>
					</form>
					<div style={{clear: 'both'}}></div>
					<div className="container" align="center" id="search-status" style={{color:'red'}}></div>
				</div>
			</section>
			
		    <section className="padd-top-20">
				<div style={{margin:15}}>
					<div className="">
					
					<h3 style={{marginLeft:0, marginTop:40, marginBottom:20}}> Goods </h3>
						
						{!values.goodLoaded?
													
							<div align="left" style={{margin:20}}>
								<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
							</div> :
							
							<div>
								
								{goodCategories.length == 0 ?

									<div align="center" style={{marginTop:10, fontSize:16, color: '#FF465A'}}>
										No goods category yet
									</div> :

									<div>  
										
										<div class="row-eq-height translucent">

											{goodCategories.map( (good, index) => { 
												
												if(values.screen_width < 767) {
													if(index <= 6) {
														return <div className="category col-xs-3 col-sm-2 col-md-2" align="center" key={index}>
															<Link to={`/ads/category/${good.id}`}>
																{good.icon == '' || good.icon == null ?
																	<img src={window.location.origin + '/assets/img/html5.png'} alt={good.name} className="category-img"/> : 

																	<img src={good.icon} alt={good.name} className="category-img"/>
																}

																<p className="category-name">{Functions.capitalizeFirstLetter(good.name)}</p>
															</Link>
															
														</div>
													}
												} else {
													return <div className="category col-xs-3 col-sm-2 col-md-2" align="center" key={index}>
														<Link to={`/ads/category/${good.id}`}>
															
															{good.icon == '' || good.icon == null ?
																<img src={window.location.origin + '/assets/img/html5.png'} alt={good.name} className="category-img"/> : 

																<img src={good.icon} alt={good.name} className="category-img"/>
															}

															<p className="category-name">{Functions.capitalizeFirstLetter(good.name)}</p>
														</Link>
														
													</div>
												}
											})}

											{values.screen_width < 767 ?
												<div className="col-xs-3 col-sm-2 col-md-2" align="center" style={{color: '#333', cursor: 'pointer'}} onClick={seeMore.bind(this, 'goods')}>
													<img src={window.location.origin + '/assets/img/more.png'} alt="More icon" className="category-img"/><p></p>
													See More
												</div> : null
											}
										</div>

										{values.showOtherGoods ?
											(values.goodLoaded ?
												<div>
													<br/>
													
													<select className="form-control" name="goods" onChange={selectCategory}>
														<option value=""> -- Select other goods-- </option>
															{goodCategories.map( (good_category, index) => {
																if(index > 6) {
																	return <option key={index} value={good_category.id}>
																		{good_category.name}
																	</option>
																}}
															)}
													</select>
												</div> : 
												<p>  Retrieving goods categories ...</p>
											) : null
										}
									</div>
									
								}
							</div>
						}

						<h3 style={{marginLeft:0, marginTop:70, marginBottom:20}}> Services </h3>
						
						{!values.serviceLoaded?
													
							<div align="left" style={{margin:20}}>
								<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
							</div> :
							
							<div>
								
								{serviceCategories.length == 0 ?

									<div align="center" style={{marginTop:10, fontSize:16, color: '#FF465A'}}>
										No services category yet
									</div> :

									<div>
										
										<div class="row-eq-height translucent">

											{serviceCategories.map( (service, index) => { 
												
												if(values.screen_width < 767) {
													if(index <= 10) {
														return <div className="category col-xs-3 col-sm-2 col-md-2" align="center" key={index}>
															<Link to={`/ads/category/${service.id}`}>
																{service.icon == '' || service.icon == null ?
																	<img src={window.location.origin + '/assets/img/html5.png'} alt={service.name} className="category-img"/> : 

																	<img src={service.icon} alt={service.name} className="category-img"/>
																}

																<p className="category-name">{Functions.capitalizeFirstLetter(service.name)}</p>
															</Link>
															
														</div>
													}
												} else {
													return <div className="category col-xs-3 col-sm-2 col-md-2" align="center" key={index}>
														<Link to={`/ads/category/${service.id}`}>
															
															{service.icon == '' || service.icon == null ?
																<img src={window.location.origin + '/assets/img/html5.png'} alt={service.name} className="category-img"/> : 

																<img src={service.icon} alt={service.name} className="category-img"/>
															}

															<p className="category-name">{Functions.capitalizeFirstLetter(service.name)}</p>
														</Link>
														
													</div>
												}
											})}

											{values.screen_width < 767 ?
												<div className="category col-xs-3 col-sm-2 col-md-2" align="center" style={{color: '#000', cursor: 'pointer'}} onClick={seeMore.bind(this, 'services')}>
													<img src={window.location.origin + '/assets/img/more.png'} alt="More icon" className="category-img"/><p></p>
													See More
												</div> : null
											}
										</div>
										
										{values.showOtherServices ?
											(values.serviceLoaded ?
												<div>
													<br/>
													
													<select className="form-control" name="services" onChange={selectCategory}>
														<option value=""> -- Select other services-- </option>
															{serviceCategories.map( (service_category, index) => {
																if(index > 6) {
																	return <option key={index} value={service_category.id}>
																		{service_category.name}
																	</option>
																}}
															)}
													</select>
												</div> : 
												<p>  Retrieving services categories ...</p>
											) : null
										}
											
									</div>
								}

							</div>
						}
						
						<div>
							
							<h3 style={{marginLeft:0, marginTop:70, marginBottom:20}}> Latest Ads </h3>

							<div>
								
								{!values.productLoaded?
									
									<div align="center" style={{marginTop:30}}>
										<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> <br/><br/>
									</div> :
									
									<div>

										{values.products.length == 0 ?
												
											<div align="center" style={{marginTop:100, fontSize:40, color: '#EA7602'}}>
												<i className="fa fa-bullhorn"></i> <br/><br/>
												No Ads Yet
											</div> :
											
											<ul>
												
												{values.products.map( (product, index) => 
													<TheProduct
														key = {index}
														from = 'home'
														id = {product.id}
														name = {product.name}
														price = {product.price}
														picture = {product.picture}
														category = {product.category}
														seller_institution = {product.seller_institution}
														seller_phone = {product.seller_phone}
														added_on = {product.created_at}
														rating = {product.rating}
													/>
												)}

											</ul>
										}

									</div>
								}
							</div>
							
							<div style={{clear: 'both'}}></div>
						</div>
						
						{values.productLoaded && values.products.length > 0?
							<div align="center">
								<div style={{clear: 'both'}}></div>
								<Link to="/ads" className="btn theme-btn" style={{border:'1px solid #fff'}}>View All <i className="fa fa-arrow-circle-right"></i>  </Link>
							</div> : null
						}
					</div>
				</div>
			</section>

			<section class="features" style={{backgroundColor: '#eee'}}>
				<div class="container">
					<div class="row">
						<div class="col-md-10 col-md-offset-1">
						<div class="heading">
							<h2 style={{marginLeft:50}}>How <span>Bineza</span> Works</h2>
						</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-4">
						<div class="feature-box">
							<span class="ti-search"></span>
							<h4>Search </h4>
							<p>Search for your needed goods or services</p>
						</div>
					</div>
					<div class="col-md-4 col-sm-4">
						<div class="feature-box">
							<span class="ti-user"></span>
							<h4>Contact the Vendor</h4>
							<p>Interested in the vendor? Give him or her a call.</p>
						</div>
					</div>
					<div class="col-md-4 col-sm-4">
						<div class="feature-box">
							<span class="ti-money"></span>
							<h4>Strike A Deal</h4>
							<p>Pay in exchange of the goods or services</p>
						</div>
					</div>
				</div>
			</section>

			<section class="bg-image" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/bg-simple.jpg')})`}} data-overlay="7">
				<div class="container">
					
					<div class="row">
						<div class="col-md-10 col-md-offset-1">
						<div class="heading light">
							<h2>Top Sellers <span>Of the Month</span></h2>
						</div>
						</div>
					</div>
					
					<div class="">

						{!values.topSellerLoaded?
									
							<div align="center" style={{marginTop:100}}>
								<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
							</div> :
							
							<div>
							
								{values.top_sellers.length == 0 ?
									
									<div align="center" style={{fontSize:20}}>
										<p style={{color: '#fff'}}> 
											No top seller yet
										</p>
									</div> :
							
									<div>
										{values.top_sellers.map( (seller, index) =>
											<div class="col-md-3 col-sm-6 col-xs-12">
												<div class="tp-author-box">
													<br/>
													<div style={{marginBottom:0}}>
													
														{seller.picture ? 
									
															<div align="center" className="edit-info">
																<img src={seller.picture} className="avater-box" alt=""/>
															</div> : 

															<div align="center" className="edit-info">
																<img alt="" src={window.location.origin + '/assets/img/avatar.png'} className="avater-box"/>
															</div>
														}
														
														<div style={{clear:'both'}}></div>
														<h4 className="title-name" style={{marginTop:20}} align="center">
															<Link to={`/seller/${seller.business_url}`} style={{cursor: 'pointer'}}>{seller.fullname}</Link>
														</h4>
														<p  align="center" className="title-name">{seller.institution}</p>
													</div>
													
													<div class="tp-author-detail-wrapper">
														<div class="tp-author-basic-info">
															<ul>
																<li><strong>Ads</strong>{seller.ads}</li>
																<li><strong>Reviews</strong>{seller.total_ratings}</li>
																<li><strong>Business</strong>{seller.business}</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								}
							</div>
						}
					</div>
				</div>
			</section>

			<section class="">
				<div class="container">
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="do-you text-center">
								<h3 style={{fontSize: 20}}>Do you want to sell goods and services on Bineza?</h3> <br/>
							</div>
							<div class="do-you" align="center">
								<Link to="/register" class="btn theme-btn" style={{border:'1px solid #fff'}}><i class="fa fa-user-circle"></i> Start Trading!</Link>
							</div>
						</div>
					</div>
				</div>
			</section>

            <Footer/>
					
            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
			
			<button style={{display: 'none'}} type="button" id="homeModal" className="btn btn-primary" data-toggle="modal" data-target="#safetyTipsModal"> Safety Tips </button>

			<div class="modal fade" style={{marginTop:100}} id="safetyTipsModal" tabindex="-1" role="dialog" aria-labelledby="safetyTipsModalTitle" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title" id="safetyTipsModalLongTitle">Safety tips for customers</h4>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<ul>
								<li>Avoid paying before delivery. If you must pay before delivery, ensure the seller is trustworthy. </li>
								<li>Meet with buyers and sellers in a safe and secure location. </li> 
								<li> Double check the item before making payment. </li>
								<li> Be observant. </li>
								<li> Paying for a service? How about a 50% initial deposit, so you don't lose all your money if the deal doesn't work out as planned. </li>
							</ul>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-info" onClick={dontShowModal}>Don't show this again</button>
							<button type="button" class="btn btn-secondary" id="close-modal-btn" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>

		</div>
    );
}
