import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { Link } from "react-router-dom";
import Functions from '../../utils/Functions';
import axios from 'axios';
import { serverEndPoint, webTitle } from '../../utils/Helpers';

export default function TheRelatedProduct(props) {
  
    const product_link = `/ads/details/${props.id}`;
    let rating_div = `rating-status${props.id}`;
    let price;
    
    if(Functions.isInt(props.price)) {
        price = `₦${Functions.formatPrice(props.price)}`; 
    } else {
        price = props.price;
    }

    let [values, setValues] = React.useState({
        reviewLoaded: false,
        showContact: false,
        count_reviews: 0,
        ratings: '',
        rating: 0,
    });

    async function showContact(seller) {
        setValues({
            ...values,
            showContact: true
        });
    }

    async function getProductReviews() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/reviews/${props.id}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            const details = response.data.data.reviews;
            const count_reviews = response.data.data.count_reviews;
            const rating = response.data.data.rating;
            
            if(response.status == 200) {  
				setValues( values => ({
					...values, 
                    reviews: details,
                    count_reviews: count_reviews,
                    rating: rating,
                    reviewLoaded: true,
                }));

                await displayRatingStars(props.id, rating);
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }

    async function displayRatingStars(ads_id, rating) {
        let rating_div = document.getElementById(`rating-status${ads_id}`);
        
        if(rating == 'None') {
            rating_div.innerHTML = rating;
        } else {

            if(Functions.isInt(rating)) {
                for (let i = 1; i <= rating; i++) {
                    rating_div.innerHTML+= '<i class="fa fa-star"></i>';
                }
            } else {
                for (let i = 1; i <= Math.floor(rating); i++) {
                    rating_div.innerHTML+= '<i class="fa fa-star"></i>';
                }
                rating_div.innerHTML+= '<i class="fa fa-star-half-o"></i>';
            }

        }
    }

    useEffect( () => {
        getProductReviews();
    }, []);

    return (

        <span>
            <li class="items-list">
                                    
                <Link to={product_link}>
                    {props.picture == 'none' ?
                        <img src={window.location.origin + '/assets/img/logo-white.png'} className="product-img" alt=""/> :
                        <img src={props.picture} className="product-img" alt=""/>
                    }
                </Link>

                <div class="righttxt">
                    
                    <div class="rightdetails">
                        <div class="relatedpricetext"><a href={product_link}>{Functions.capitalizeFirstLetter(props.name)} </a></div>
                        <div style={{color:'#EA7602'}}>{price}</div>
                        
                        <span style={{color:'#EA7602'}}>
                            {values.reviewLoaded ? 
                                <small id={rating_div}></small> : null  
                            }
                        </span>

                        <small>{props.added_on}</small><br/>
                        <small className="instext">{props.seller_institution}</small> 
                        
                        {/* <div align="left" style={{clear: 'both', position:'relative'}}>
                            
                            <a href="#" className="show-contact-side text-info" onClick={showContact.bind(this, props.id)}>
                                {!values.showContact ?
                                    <span style={{textAlign: 'left'}}>Show Contact</span> :
                                    <a href={`tel:${props.seller_phone}`}><i className="fa fa-phone"></i> {props.seller_phone} </a>
                                }
                            </a>
                        </div> */}
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div> 

                <div className="ads-contact">
                    
                    <a href={`tel:${props.seller_phone}`} className="call-area">
                        <i className="fa fa-phone"></i> Call
                    </a>

                    <a href={`sms:${props.seller_phone}?body=Hello, I want to buy ${props.name}`} className="sms-area">
                        <i className="fa fa-envelope"></i> SMS
                    </a>

                </div>

            </li>
            
        </span>
    );
}
