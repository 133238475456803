import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";

export default function Footer() {
    
    return (
        <footer className="footer dark-footer dark-bg">
            <div className="container">
                <div className="row">
                
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="footer-widget">
                            <h3 className="widgettitle widget-title">About Us</h3>
                            <p>Bineza is a platform that connects sellers and buyers in various tertiary Institutions in Nigeria. <br/>
                                With us, you are easily connected. </p>
                        </div>
                    </div>
                    
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="footer-widget">
                            <h3 className="widgettitle widget-title">Links</h3>
                            <ul className="footer-navigation sinlge">
                                <li><a href="/faq">FAQ</a></li>
                                <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="footer-widget">
                            <div className="textwidget">
                            <h3 className="widgettitle widget-title">Get In Touch</h3>
                            <div className="address-box">
                                <div className="sing-add">
                                    <i className="ti-location-pin"></i>Abuja, Nigeria
                                </div>
                                <div className="sing-add">
                                    <i className="ti-email"></i><a href="mailto:info@bineza.com">info@bineza.com</a>
                                </div>
                                <div className="sing-add">
                                    <i className="ti-mobile"></i><a href="tel:+2348131394079">+2348131394079</a>
                                </div>
                            </div>

                            <ul className="footer-social">
                                <li><a href="http://www.facebook.com/binezanigeria" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="http://www.twitter.com/binezanigeria" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li><a  href="http://www.instagram.com/binezanigeria" target="_blank"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="footer-widget">
                            <h3 className="widgettitle widget-title">Subscribe Newsletter</h3>
                            <p>Get our regular updates directly in your mail</p>
                            
                            <form className="sup-form">
                                <input type="email" className="form-control sigmup-me" placeholder="Your Email Address" required="required"/>
                                <button type="submit" className="btn" value="Get Started"><i className="fa fa-location-arrow"></i></button>
                            </form>
                        </div>
                    </div> */}
                </div>
                
            </div>
            <div className="footer-copyright">
                <p>Designed by <a href="https://www.glinix.com">Glinix</a></p>
            </div>
        </footer>
    );
}
