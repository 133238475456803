import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { Link } from "react-router-dom";
import Functions from '../../utils/Functions';
import axios from 'axios';
import { serverEndPoint, webTitle } from '../../utils/Helpers';

export default function TheProduct(props) {
  
    const product_link = `/ads/details/${props.id}`;
    let rating_div = `rating-status${props.id}`;

    let [values, setValues] = React.useState({
        reviewLoaded: false,
        showContact: false,
        count_reviews: 0,
        ratings: '',
        rating: 0,
    });

    async function showContact(seller) {
        setValues({
            ...values,
            showContact: true
        });
    }

    function displayRatingStars(ads_id, rating) {
        let rating_div = document.getElementById(`rating-status${ads_id}`);
        
        if(rating == 'None') {
            rating_div.innerHTML = rating;
        } else {

            if(Functions.isInt(rating)) {
                for (let i = 1; i <= rating; i++) {
                    rating_div.innerHTML+= '<i class="fa fa-star"></i>';
                }
            } else {
                for (let i = 1; i <= Math.floor(rating); i++) {
                    rating_div.innerHTML+= '<i class="fa fa-star"></i>';
                }
                rating_div.innerHTML+= '<i class="fa fa-star-half-o"></i>';
            }
        }
    }

    useEffect( () => {
        displayRatingStars(props.id, props.rating);
    }, []);

    return (

        <span>
            {props.from == 'home'?  
                <li class="items-list">
                    
                    <Link to={product_link}>
                        {props.picture == 'none' ?
                            <img src={window.location.origin + '/assets/img/logo-white.png'} className="product-img" alt=""/> :
                            <img src={props.picture} className="product-img" alt=""/>
                        }
                    </Link>
                    
                    <div class="righttxt">
                        
                        <div class="rightdetails">
                            <div class="pricetext"><Link to={product_link}>{Functions.capitalizeFirstLetter(props.name)} </Link></div>
                            <div class="titletext">
                                <span align="left" style={{color:'#EA7602', marginRight: 20}}>
                                    {Functions.isInt(props.price) ?
                                        <span>&#8358;{Functions.formatPrice(props.price)}</span> : 
                                        <span>{props.price}</span>
                                    }
                                </span> <br/>
                                <span style={{color:'#EA7602'}}>
                                    <span>
                                        <small id={rating_div}></small> &nbsp; &nbsp;
                                        <small className='instext-mobile'>{props.added_on}</small> 
                                    </span> 
                                </span>
                            </div>
                            
                            <small className="instext">{props.seller_institution}</small> 
                            <small id="ads-details-desktop">{props.added_on}</small> 
                            
                            <div style={{position:'relative'}}>
                                <button className="show-contact-side btn btn-sm btn-default" onClick={showContact.bind(this, props.id)}>
                                    {!values.showContact ?
                                        <span style={{textAlign: 'center'}}>Show Contact</span> :
                                        <a href={`tel:${props.seller_phone}`}><i className="fa fa-phone"></i> {props.seller_phone} </a>
                                    }
                                </button>
                            </div>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </div> 
                    
                    <div className="ads-contact">
                        
                        <a href={`tel:${props.seller_phone}`} className="call-area">
                            <i className="fa fa-phone"></i> Call
                        </a>

                        <a href={`sms:${props.seller_phone}?body=Hello, I want to buy ${props.name}`} className="sms-area">
                            <i className="fa fa-envelope"></i> SMS
                        </a>
                    
                    </div>

                </li> :

                <li class="items-list">
                                        
                    <Link to={product_link}>
                        {props.picture == 'none' ?
                            <img src={window.location.origin + '/assets/img/logo-white.png'} className="product-img" alt=""/> :
                            <img src={props.picture} className="product-img" alt=""/>
                        }
                    </Link>

                    <div class="righttxt">
                        
                        <div class="rightdetails">
                            <div class="pricetext"><Link to={product_link}>{Functions.capitalizeFirstLetter(props.name)} </Link></div>
                            <div class="titletext">
                                <span align="left" style={{color:'#EA7602', marginRight: 20}}>
                                    {Functions.isInt(props.price) ?
                                        <span>&#8358;{Functions.formatPrice(props.price)}</span> : 
                                        <span>{props.price}</span>
                                    }
                                </span> <br/>
                                <span style={{color:'#EA7602'}}>
                                    <span>
                                        <small id={rating_div}></small> &nbsp; &nbsp;
                                        <small className='instext-mobile'>{props.added_on}</small> 
                                    </span> 
                                </span>
                            </div>
                            
                            <small className="instext">{props.seller_institution}</small> 
                            <small id="ads-details-desktop">{props.added_on}</small> 
                            
                            <div style={{position:'relative'}}>
                                <button className="show-contact-side btn btn-sm btn-default" onClick={showContact.bind(this, props.id)}>
                                    {!values.showContact ?
                                        <span style={{textAlign: 'center'}}>Show Contact</span> :
                                        <a href={`tel:${props.seller_phone}`}><i className="fa fa-phone"></i> {props.seller_phone} </a>
                                    }
                                </button>
                            </div>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </div> 

                    <div className="ads-contact">
                        
                        <a href={`tel:${props.seller_phone}`} className="call-area">
                            <i className="fa fa-phone"></i> Call
                        </a>

                        <a href={`sms:${props.seller_phone}?body=Hello, I want to buy ${props.name}`} className="sms-area">
                            <i className="fa fa-envelope"></i> SMS
                        </a>

                    </div>

                </li>
            }
        </span>
    );
}
