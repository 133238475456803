import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../navs/Header';
import Footer from '../navs/Footer';
import CustomerHooks from '../hooks/CustomerHooks';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle } from '../../utils/Helpers';
import { Link } from 'react-router-dom';

export default function CustomerProfile() {
	
	const data = CustomerHooks();

	return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}} >
				<div className="container">
					<div className="title-content">
						<h1>My Profile</h1>
					</div> <br/><br/>
				</div>
			</section>
        
            <section className="padd-0">
				<div className="container">
					<div className="add-listing-box translateY-60 edit-info mrg-bot-25 padd-bot-30 padd-top-25">
						
						<div style={{margin:10}}>
							<a href="/customers/edit-profile" className="pull-right btn btn-warning"><i className="fa fa-pencil"></i> Update Profile</a>
						</div>

						<div className="listing-box-header">
							{data.picture ? 
								
								<div>
									<img src={data.picture} className="avater-box" alt=""/>
								</div> : 

								<div className="avater-box">
									<img alt="" src={window.location.origin + '/assets/img/avatar.png'} className="img-responsive img-circle"/>
								</div>
							}
						</div>
						
						<div className="container preview-info">
								
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-user preview-icon call mrg-r-10"></i>{Functions.capitalizeWord(data.firstname+" "+data.lastname)}</label>
							</div>
							
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-mobile preview-icon call mrg-r-10"></i>{data.phone}</label>
							</div>
							
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-email preview-icon email mrg-r-10"></i><a href="#">{data.email}</a></label>
							</div>
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-gift preview-icon birth mrg-r-10"></i>{data.dob}</label>
							</div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <label><i className="ti-location-pin preview-icon birth mrg-r-10"></i>{data.city}, {data.state}</label>
							</div>
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-home preview-icon birth mrg-r-10"></i>{data.address}</label>
							</div>
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-book preview-icon birth mrg-r-10"></i>{data.institution_name}</label>
							</div>
							
							<br/>
								
							<div className="col-sm-12 col-xs-12 col-md-12">
								<h4> About </h4>
								<label>{data.about}</label>
							</div>
                    	</div>
					</div>
				</div>
			</section>

			<Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
