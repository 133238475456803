import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import Header from './navs/Header';
import Footer from './navs/Footer';
import NavLinks from './navs/NavLinks';
import { serverEndPoint, webTitle } from '../utils/Helpers';
import Functions from '../utils/Functions';

export default function Contact() {
    
    const [values, setValues] = React.useState({
        email: '',
        fullname: '',
        message: '',
    });

    function onChangeHandler(event) {
        const { name, value } = event.target;
        setValues(values => ({...values, [name] : value}));
    }

    async function contactUs(event) {
    
        event.preventDefault();
        const status = document.getElementById('contact-status');
        const btn = document.getElementById('contact-btn');
          
        const { email, fullname, message } = values;        
        const allValues = [email, fullname, message];
        const isValueEmpty = Functions.isEmpty(allValues);

        if(isValueEmpty) {
            status.innerHTML = "<p style='color:red'> Fill all fields </p>";
            return false;
        } else {
            
            Functions.disableBtn(btn);
            const data = `fullname=${fullname}&email=${email}&message=${message}`; 
            
            axios({
                method: 'post',
                url: `${serverEndPoint}contact-us`,
                data: data,
            })
            .then( async (response) => {
                Functions.enableBtn('Send message', btn);
                console.log(response);

                if(response.status == "200") {
                    status.innerHTML = `<p style='color:green'>Message sent. We shall get back to you shortly</p>`;
                    document.getElementById('contactus-form').reset();
                } else {
                    status.innerHTML = `<p style='color:red'>Message not sent. Try again.</p>`;
                }
            })
            .catch( (error) => {
                Functions.enableBtn('Send message', btn);
                status.innerHTML = "<p style='color:red'>Message not sent. Try again.</p>";
            });
        }     
    }

    useEffect( () => {
        document.title = webTitle+" Contact Us";
    });
    
    return (

        <div>

            <div class="main-container">

                <Header/> 
                
                <section class="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}} >
                    <div class="container">
                        <div class="title-content">
                            <h1>We are available 24/7</h1>
                        </div>
                    </div>
                </section>
                <div class="clearfix"></div>

                <section class="padd-0">
                    <div class="container">
                        <div class="col-md-12 col-sm-12 translateY-60">
                            <div class="col-md-4 col-sm-4">
                                <div class="detail-wrapper text-center padd-top-40 mrg-bot-10 padd-bot-40 light-bg">
                                    <i class="theme-cl font-30 ti-email"></i>
                                    <h4>info@bineza.com</h4>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="detail-wrapper text-center padd-top-40 mrg-bot-10 padd-bot-40 light-bg">
                                    <i class="theme-cl font-30 ti-mobile"></i>
                                    <h4>+2348131394079</h4>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="detail-wrapper text-center padd-top-40 mrg-bot-10 padd-bot-40 light-bg">
                                    <i class="theme-cl font-30 ti-location-pin"></i>
                                    <h4>Abuja, Nigeria</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section class="padd-top-0">
                    <div class="container">
                        <div class="col-md-12 col-sm-12">
                            <h4> Send us a message </h4> <hr/>
                            <form method="post" action="#" id="contactus-form" onSubmit={event => contactUs(event)}>
                                <div class="form-group">
                                    <label>Fullname:</label>
                                    <input type="text" name="fullname" class="form-control" onChange={onChangeHandler}/>
                                </div>
                                <div class="form-group">
                                    <label>Email address:</label>
                                    <input type="email" name="email" class="form-control" onChange={onChangeHandler}/>
                                </div>
                                <div class="form-group">
                                    <label>Message:</label>
                                    <textarea class="form-control height-120" name="message" onChange={onChangeHandler}></textarea>
                                </div>
                                <div class="form-group">
                                    <button class="btn theme-btn" id="contact-btn" name="submit"><i class="fa fa-location-arrow"></i> Send Message</button>
                                </div>
                                <br/>
                                <div id="contact-status"></div>
                            </form>
                        </div>
                    </div>
                </section>
                
                <Footer/>

                <a href="#" class="scroll-up"><span class="ti-arrow-up"></span></a>

            </div>
        </div>
    );
}
