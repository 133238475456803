import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Functions from '../../../utils/Functions';
import { serverEndPoint, webTitle, sellerCookie } from '../../../utils/Helpers';
import swal from 'sweetalert';

export default function TheProduct(props) {
  
    const product_link = `/sellers/ads/details/${props.id}`;
    const product_status = `product-status${props.id}`;
    let rating_div = `rating-status${props.id}`;

    let [values, setValues] = React.useState({
        count_reviews: 0,
        rating: 0,
    });

    function displayRatingStars(ads_id, rating) {
        let rating_div = document.getElementById(`rating-status${ads_id}`);
        
        if(rating == 'None') {
            rating_div.innerHTML = rating;
        } else {

            if(Functions.isInt(rating)) {
                for (let i = 1; i <= rating; i++) {
                    rating_div.innerHTML+= '<i class="fa fa-star"></i>';
                }
            } else {
                for (let i = 1; i <= Math.floor(rating); i++) {
                    rating_div.innerHTML+= '<i class="fa fa-star"></i>';
                }
                rating_div.innerHTML+= '<i class="fa fa-star-half-o"></i>';
            }

        }
    }

    async function deleteAds(ads_id, ads_name) {
        
        const status = document.getElementById(`product-status${ads_id}`);
        
        swal({
            text: `Are you sure you want to delete ${ads_name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then( async (response) => {

            if (response) {
                
                status.innerHTML = 'Please wait ...';
        
                await axios({
                    method: 'delete',
                    url: serverEndPoint+`sellers/product/delete/${ads_id}`,
                    headers : {
                        'Content-Type' : 'application/json',
                        'x-access-token' : sellerCookie,
                    }
                })
                .then( async (response) => {
                    const details = response.data.data;
                    
                    if(response.data.data) {  
                        status.innerHTML = "<p style='color:green'> Ads successfully deleted </p>";
                        window.location = "/sellers/ads";
                    } else {
                        status.innerHTML = "<p style='color:red'> Ads not deleted. Try again. </p>";
                    }
                }).
                catch( (error) => {
                    status.innerHTML = "<p style='color:red'> Ads not deleted. Try again. </p>";
                });
            } else {
                status.innerHTML = '';
            }
        });
    }
    
    useEffect( () => {
        displayRatingStars(props.id, props.rating);
    }, []);

    return (
        
        <li class="items-list">
                                        
            <Link to={product_link}>
                {props.picture == 'none' ?
                    <img src={window.location.origin + '/assets/img/logo-white.png'} className="product-img" alt=""/> :
                    <img src={props.picture} className="product-img" alt=""/>
                }
            </Link>

            <div class="righttxt">
                
                <div class="rightdetails">
                    <div class="pricetext"><Link to={product_link}>{Functions.capitalizeFirstLetter(props.name)} </Link></div>
                    <div class="titletext">
                        <span align="left" style={{color:'#EA7602', marginRight: 20}}>
                            {Functions.isInt(props.price) ?
                                <span>&#8358;{Functions.formatPrice(props.price)}</span> : 
                                <span>{props.price}</span>
                            }
                        </span> <br/>
                        <span style={{color:'#EA7602'}}>
                            <span>
                                <small id={rating_div}></small> &nbsp; &nbsp;
                                <small>{props.added_on}</small> 
                            </span>
                        </span>
                    </div>
                    
                    <small className="instext">{props.category}</small> 
                    
                    <div style={{position:'relative'}}>
                        
                        <div className="show-seller-contact-side btn-group">
                            
                            <button type="button" className="button gray dropdown-toggle" data-toggle = "dropdown">Actions
                                <span className="caret"></span>
                            </button>
                            
                            <ul className="dropdown-menu" role="menu" style={{padding:5}}>
                                <li><Link to={`/sellers/ads/edit/${props.id}`}>Edit Ad</Link></li>
                                <li><Link to="#" onClick={deleteAds.bind(this, props.id, Functions.capitalizeFirstLetter(props.name))}>Delete Ad</Link></li>
                            </ul>
                        </div>
                    </div>
            
                </div>
                <div style={{clear: 'both'}}></div>
            </div> 

            <div className="ads-contact">
                
                <Link to={`/sellers/ads/edit/${props.id}`} className="edit-ads-area">
                    <i className="fa fa-pencil"></i> Edit
                </Link>

                <Link to="#" onClick={deleteAds.bind(this, props.id, Functions.capitalizeFirstLetter(props.name))} className="delete-ads-area">
                    <i className="fa fa-trash"></i> Delete
                </Link>
                <div style={{clear: 'both'}}></div> <br/>
            </div>
                        
            <br/>
            <div id={product_status} align="center"></div>
            <br/><br/>
        </li>
    );
}
