import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../navs/Header';
import Footer from '../navs/Footer';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle, sellerCookie} from '../../utils/Helpers';

export default function SellerChangePassword() {
    
    let [values, setValues] = React.useState({
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showNewPasswordConfirmation: false,
    });
    
    function onChangeHandler(event) {
        const { name, value } = event.target;
        setValues(values => ({...values, [name] : value}));
    }

    function togglePassword(field, action) {
        const password = document.getElementById(field);

        if(action == 'show') {
            password.type = 'text';  
            
            if(field == 'current_password') {
                setValues(values => ({
                    ...values, 
                    showCurrentPassword: true
                }));
            } else if(field == 'new_password') {
                setValues(values => ({
                    ...values, 
                    showNewPassword: true
                }));
            } else if(field == 'new_password_confirmation') {
                setValues(values => ({
                    ...values, 
                    showNewPasswordConfirmation: true
                }));
            } 

        } else {
            password.type = 'password';  
            
            if(field == 'current_password') {
                setValues(values => ({
                    ...values, 
                    showCurrentPassword: false
                }));
            } else if(field == 'new_password') {
                setValues(values => ({
                    ...values, 
                    showNewPassword: false
                }));
            } else if(field == 'new_password_confirmation') {
                setValues(values => ({
                    ...values, 
                    showNewPasswordConfirmation: false
                }));
            }    
        }
    }
    
    function changePassword(event) {
    
        event.preventDefault();
        const status = document.getElementById('change-password-status');
        const btn = document.getElementById('change-password-btn');
          
        const { current_password, new_password, new_password_confirmation } = values;        
        const allValues = [current_password, new_password, new_password_confirmation];
        const isValueEmpty = Functions.isEmpty(allValues);

        if(isValueEmpty) {
            status.innerHTML = "<p style='color:red'> Fill all fields </p>";
            return false;
        } else {
            
            Functions.disableBtn(btn);
            const data = `current_password=${current_password}&new_password=${new_password}&new_password_confirmation=${new_password_confirmation}`;  
  
            axios({
                method: 'put',
                url: `${serverEndPoint}sellers/update/password`,
                data: data,
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept' : 'application/json',
                    'x-access-token' : sellerCookie
                }
            })
            .then( async (response) => {
                Functions.enableBtn('Change Password', btn);
                
                if(response.data.data.type == "success") {
                    await Functions.deleteCookie('bineza_seller_token');
                    status.innerHTML = `<p style='color:green'>Password changed</p>`;
                    window.location = "/login";
                } else {
                    status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
                }
            }).
            catch( (error) => {
                Functions.enableBtn('Change Password', btn);
                status.innerHTML = "<p style='color:red'> Password not changed. Try again. </p>";
            });
        }     
    }
    
    useEffect( () => {
        document.title = webTitle+"Change Password";
    });

    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}} >
				<div className="container">
					<div className="title-content">
						<h1>Change Password</h1>
					</div>
				</div> <br/><br/>
			</section>
        
            <section className="padd-0">
				<div className="container">
					<div className="add-listing-box translateY-60 edit-info mrg-bot-25 padd-bot-30 padd-top-25">
						
                    <form method="post" action="#" onSubmit={event => changePassword(event)}>
                            <div className="mrg-r-10 mrg-l-10">
                                <div>
                                    <label>Current Password</label>
                                    <div class="input-group">
                                        <input type="password" id="current_password" class="form-control" name="current_password" onChange={onChangeHandler}/>
                                        <span class="input-group-addon">
                                            {!values.showCurrentPassword ?
                                                <span onClick={togglePassword.bind(this, 'current_password', 'show')} style={{color: '#EA7602', cursor: 'pointer'}}><b>Show</b></span> :
                                                <span onClick={togglePassword.bind(this, 'current_password', 'hide')} style={{color: '#EA7602', cursor: 'pointer'}}><b>Hide</b></span>
                                            }
                                        </span>
                                    </div>
                                </div> <br/>

                                <div>
                                    <label>New Password</label>
                                    <div class="input-group">
                                        <input type="password" id="new_password" class="form-control" name="new_password" onChange={onChangeHandler}/>
                                        <span class="input-group-addon">
                                            {!values.showNewPassword ?
                                                <span onClick={togglePassword.bind(this, 'new_password', 'show')} style={{color: '#EA7602', cursor: 'pointer'}}><b>Show</b></span> :
                                                <span onClick={togglePassword.bind(this, 'new_password', 'hide')} style={{color: '#EA7602', cursor: 'pointer'}}><b>Hide</b></span>
                                            }
                                        </span>
                                    </div>
                                </div> <br/>
                            
                                <div>
                                    <label>Confirm New Password</label>
                                    <div class="input-group">
                                        <input type="password" id="new_password_confirmation" class="form-control" name="new_password_confirmation" onChange={onChangeHandler}/>
                                        {!values.showNewPasswordConfirmation ?
                                            <span class="input-group-addon" onClick={togglePassword.bind(this, 'new_password_confirmation', 'show')} style={{color: '#EA7602', cursor: 'pointer'}}><b>Show</b></span> :
                                            <span class="input-group-addon" onClick={togglePassword.bind(this, 'new_password_confirmation', 'hide')} style={{color: '#EA7602', cursor: 'pointer'}}><b>Hide</b></span>
                                        }
                                    </div>
                                </div> <br/><br/>

                                <div className="text-center">
                                    <button type="submit" id="change-password-btn" className="btn btn-warning" title="Update Profile"><i className="fa fa-check"></i> Change Password</button>
                                    <br/><br/>
                                    <div id="change-password-status" align="center"></div>
                                </div>
                            </div>
                        </form>
					</div>
				</div>
			</section>
			
            <Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
