import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Header from '../navs/Header';
import Footer from '../navs/Footer';
import SellerHooks from '../hooks/SellerHooks';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle } from '../../utils/Helpers';

export default function SellerProfile() {
	
	const data = SellerHooks();
	
	function copyBusinessLink() {
		const copy_btn = document.getElementById("copy-btn");
		const business_url = document.getElementById("business_url");
		const snackbar = document.getElementById("snackbar");
		business_url.select();
		document.execCommand('copy');
		copy_btn.innerHTML = "<b><i className='fa fa-check'></i> Copied!</b>";

		setTimeout(function() { 
			copy_btn.innerHTML = '<b>Copy</b>';
		}, 2000);

		// snackbar.className = "show";
		// setTimeout(function() { 
		// 	snackbar.className = snackbar.className.replace("show", ""); 
		// }, 3000);
	}

	useEffect( () => {
        document.title = webTitle+" My Profile";
    }, []);


	return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}} >
				<div className="container">
					<div className="title-content">
						<h1>My Profile</h1>
					</div>
				</div><br/>
			</section>
        
            <section className="padd-0">
				<div className="container">
					<div className="add-listing-box translateY-60 edit-info mrg-bot-25 padd-bot-30 padd-top-25">
						
						<div style={{margin:10}}>
							<Link to ="/sellers/edit-profile" className="pull-right btn btn-warning"><i className="fa fa-pencil"></i> Update Profile</Link>
						</div>

						<div className="listing-box-header">
							{data.picture ? 
								
								<div>
									<img src={data.picture} className="avater-box" alt=""/>
								</div> : 

								<div className="avater-box">
									<img alt="" src={window.location.origin + '/assets/img/avatar.png'} className="img-responsive img-circle"/>
								</div>
							}
						</div>

						<div className="container preview-info">
								
							<div className="col-sm-8 col-md-8 col-md-offset-2 col-sm-offset-2 col-xs-12">
								<p> Your store link <a href="#" data-toggle="tooltip" data-placement="right" title="You can copy and paste the link below on any platform or send to your friends or customers so as to redirect them to your bineza store"><i className="fa fa-info-circle"></i></a></p>
                				<div class="input-group" style={{marginTop: -10}}>
									<input type="text" class="form-control" id="business_url" value={data.business_url}/>
									<span class="input-group-addon">
										<span onClick={copyBusinessLink} id="copy-btn" style={{color: '#EA7602', cursor: 'pointer'}}><b>Copy</b></span>
									</span>
								</div>
								<div id="snackbar">Copied!</div>
                            </div>
							
							<div style={{clear: 'both'}}></div>
							<br/><br/>
							
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-user preview-icon call mrg-r-10"></i>{Functions.capitalizeWord(data.firstname+" "+data.lastname)}</label>
							</div>
							
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-mobile preview-icon call mrg-r-10"></i>{data.phone}</label>
							</div>
							
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-email preview-icon email mrg-r-10"></i><a href="#">{data.email}</a></label>
							</div>
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-gift preview-icon birth mrg-r-10"></i>{data.dob}</label>
							</div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <label><i className="ti-location-pin preview-icon birth mrg-r-10"></i>{data.city}, {data.state}</label>
							</div>
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-home preview-icon birth mrg-r-10"></i>{data.address}</label>
							</div>
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-book preview-icon birth mrg-r-10"></i>{data.institution_name}</label>
							</div>
							<div className="col-sm-4 col-md-4 col-xs-12">
								<label><i className="ti-money preview-icon birth mrg-r-10"></i>{data.business} - {data.started_at}</label>
							</div>
								
							<br/><br/>
								
							<div className="col-sm-12 col-xs-12 col-md-12">
								<h4> About </h4>
								<label>{data.about}</label>
							</div>
                    	</div>
					</div>
				</div>
			</section>

			<Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
