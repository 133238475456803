import React, { useState, useEffect } from 'react';
import {useHistory, Link} from 'react-router-dom';
import axios from 'axios';
import Header from '../../navs/Header';
import Footer from '../../navs/Footer';
import Functions from '../../../utils/Functions';
import { serverEndPoint, webTitle, sellerCookie } from '../../../utils/Helpers';
import swal from 'sweetalert';

export default function ProductDetails(props) {
	
    let [values, setValues] = React.useState({
		productLoaded: false,
        reviewLoaded: false,
        relatedProductLoaded: false,
        details: [],
        related_products: [],
        reviews: [],
        count_reviews: 0,
        rating: 0,
        seller: [],
        product: props.match.params.product,
    });

    async function getProductDetails() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/details/${values.product}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200 && details) {  
				setValues( values => ({
					...values, 
                    details: details,
                    seller: details.seller,
                    productLoaded: true,
                }));
            } else {
                window.location="/404";
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }
    
    async function getProductReviews() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/reviews/${values.product}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            const details = response.data.data.reviews;
            const count_reviews = response.data.data.count_reviews;
            const rating = response.data.data.rating;
            
            if(response.status == 200) {  
				setValues( values => ({
					...values, 
                    reviews: details,
                    count_reviews: count_reviews,
                    rating: rating,
                    reviewLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }
    
    async function getRelatedProducts() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/related/${values.product}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200) {  
				setValues( values => ({
					...values, 
                    related_products: details,
                    relatedProductLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }
    
    async function deletePicture(picture_id) {
        const status = document.getElementById(`picture-status`);
        
        swal({
            text: `Are you sure you want to delete this picture?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then( async (response) => {

            if (response) {
        
                status.innerHTML = 'Please wait ...';
        
                await axios({
                    method: 'delete',
                    url: serverEndPoint+`sellers/product/picture/delete/${picture_id}`,
                    headers : {
                        'Content-Type' : 'application/json',
                        'x-access-token' : sellerCookie,
                    }
                })
                .then( async (response) => {
                    
                    if(response.data.data.type == "success") {  
                        status.innerHTML = "<p style='color:green'> Picture successfully deleted </p>";
                        window.location = `/sellers/ads/details/${values.product}`;
                    } else {
                        status.innerHTML = "<p style='color:red'> Picture not deleted. Try again. </p>";
                    }
                }).
                catch( (error) => {
                    status.innerHTML = "<p style='color:red'> Picture not deleted. Try again. </p>";
                });
            } else {
                status.innerHTML = '';
            }
        });
    }
    

	useEffect( () => {
        getProductDetails();
        getProductReviews();
		getRelatedProducts();
        document.title = webTitle+" Ads Details";
    }, []);

    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div>
                <section className="detail-section bg-image"  style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}} data-overlay="6">
                    <div className="container">
                        <div className="title-content">
                            <h1>
                            {!values.productLoaded ? 
                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
                                <div> 
                                    {Functions.capitalizeWord(values.details.name)}
                                </div>
                            }
                            </h1>
                        </div>
                    </div>
                    <div className="profile-cover-content">
                        
                        <div className="container">
                            <div className="cover-buttons">
                                <ul>
                                <li><div className="inside-rating buttons listing-rating theme-btn button-plain">
                                    <span className="value">
                                        {!values.reviewLoaded ? 
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>:
                                            <span><i className="fa fa-star"></i> {values.rating}</span>
                                        }
                                    </span></div></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="list-detail">
                    <div className="">
                        <div className="">
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                <div className="widget-boxed">
                                    <div className="widget-boxed-header">
                                        <h4><i className="ti-gallery padd-r-10"></i>Gallery
                                            <p className="pull-right">
                                                <Link to={`/sellers/ads/add/pictures/${values.product}`} style={{backgroundColor: '#EA7602'}} className="btn btn-sm btn-warning"><i className="fa fa-upload"></i> Add New Pictures</Link>
                                            </p>
                                        </h4> <br/>
                                    </div>
                                    <div className="widget-boxed-body">
                                        <div className="side-list no-border gallery-box">

                                            {!values.productLoaded ? 
                                            
                                                <div align="center">
                                                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> 
                                                </div>:
                                                
                                                (values.details.pictures.length > 0 ?
                                                    
                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                        
                                                        {values.details.pictures.map( (picture, index) => 
                                                        
                                                            <div className="col-md-4 col-sm-4 col-xs-6" key={index}>
                                                                
                                                                <div className="list-box-listing-img">
                                                                    <a data-fancybox="gallery" href={picture.path}>
                                                                        <img src={picture.path} className="product-img" alt=""/>
                                                                    </a>
                                                                </div>

                                                                <p onClick={deletePicture.bind(this, picture.id)} style={{cursor: 'pointer'}} className="text-danger" align="center">
                                                                    <i className="fa fa-trash"></i> Delete 
                                                                </p><br/>
                                                            </div>
                                                        )}
                                                        
                                                        <div style={{clear: 'both'}}></div>
                                                        <div id="picture-status" align="center"></div>
                                                            
                                                    </div> : 

                                                    <div align="center" style={{fontSize:20, color: '#EA7602'}}> No pictures yet </div>
                                                
                                                )
                                            }
                                            <br/><div style={{clear: 'both'}}></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="detail-wrapper">
                                    <div className="detail-wrapper-header">
                                        <h4>Ads Description</h4>
                                    </div>
                                    <div className="detail-wrapper-body">
                                        <p>
                                        {!values.productLoaded ? 
                                            <div align="center">
                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                            </div> :
                                            <textarea className="form-control" rows="10" style={{textTransform: 'none', borderStyle: 'none', borderColor: 'transparent', overflow: 'auto', backgroundColor: '#fff', resize: 'none'}} value={values.details.description}></textarea>
                                        }
                                        </p>
                                    </div>
                                </div>
                                
                                <div className="detail-wrapper">
                                    <div className="detail-wrapper-header">
                                        <h4>{!values.reviewLoaded ? 
                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
                                                <div> {values.count_reviews} Reviews</div>
                                            }</h4>
                                    </div>
                                    <div className="detail-wrapper-body">
                                        
                                        {!values.reviewLoaded?
                                        
                                            <div align="center" style={{marginTop:10}}>
                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                            </div> :
                                            
                                            <div>
                                                {values.reviews.length == 0 ?
                                                    
                                                    <div align="center" style={{fontSize:25, color: '#EA7602'}}>
                                                        <i className="fa fa-comments"></i> <br/><br/>
                                                        No reviews yet
                                                    </div> :
                                                    
                                                    <div>
                                                <ul className="review-list">

                                                    {values.reviews.map( (review, index) => 

                                                        <li key={index}>
                                                            <div className="reviews-box">
                                                                <div className="review-body">
                                                                    <div className="review-avatar">
                                                                        {!review.customer_picture ?
                                                                            <img alt="" src={window.location.origin + '/assets/img/avatar.png'} className='avatar avatar-140 photo'/> :
                                                                            <img src={review.customer_picture} className="avatar avatar-140 photo"/>
                                                                        }
                                                                    </div>
                                                                    <div className="review-content">
                                                                        <div className="review-info">
                                                                            <div className="review-comment">
                                                                                <div className="review-author">
                                                                                    {review.customer_fullname}			
                                                                                </div>
                                                                                <div className="review-comment-stars">
                                                                                    <i className="fa fa-star"></i> {review.rating}
                                                                                </div>
                                                                            </div>
                                                                            <div className="review-comment-date">
                                                                                <div className="review-date">
                                                                                    <span>{review.created_at}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p>{review.comment}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            }
                                        </div>
                                        }
                                        
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div className="col-md-4 col-sm-12">
                                <div className="sidebar">
                                    <div className="">
                                    
                                        <div className="widget-boxed-header">
                                            <h4><i className="ti-check-box padd-r-10"></i>Related Ads</h4>
                                        </div>
                                        <div className="widget-boxed-body padd-top-5">
                                            <div className="side-list">
                                            
                                            {!values.relatedProductLoaded?
									
                                                <div align="center" style={{marginTop:10}}>
                                                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                </div> :
                                                
                                                <div>
                                                    {values.related_products.length == 0 ?
                                                        
                                                        <div align="center" style={{fontSize:25, color: '#EA7602'}}>
                                                            <br/><br/>
                                                            No related ads <br/><br/><br/>
                                                        </div> :
                                                        
                                                        <div>
                                                            <ul className="listing-list">

                                                                {values.related_products.map( (product, index) => 

                                                                    <li key={index}>
                                                                        <a href={`/ads/details/${product.id}`}>
                                                                            <div className="listing-list-img">
                                                                                {product.picture == 'none' || product.picture == null ?
                                                                                    <img src={window.location.origin + '/assets/img/logo-white.png'} className="product-img" alt=""/> :
                                                                                    <img src={product.picture} className="product-img" alt=""/>
                                                                                }
                                                                            </div>
                                                                        </a>
                                                                        <div className="listing-list-info">
                                                                            <h5><a href="#" title="Listing">{Functions.capitalizeFirstLetter(product.name)}</a></h5>
                                                                            <p style={{color:'#EA7602'}}> 
                                                                                {Functions.isInt(product.price) ?
                                                                                    <span>&#8358;{Functions.formatPrice(product.price)}</span> : 
                                                                                    <span>{product.price}</span>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
            
            <div style={{clear:'both'}}></div>
            
            <Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
