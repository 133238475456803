import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from '../navs/Header';
import Footer from '../navs/Footer';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle, customerCookie } from '../../utils/Helpers';
import TheRelatedProduct from './TheRelatedProduct';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: 'https://cdn.pixabay.com/photo/2017/01/03/02/07/vine-1948358_960_720.png',
    thumbnail: 'https://cdn.pixabay.com/photo/2017/01/03/02/07/vine-1948358_960_720.png',
  },
  {
    original: 'https://cdn.pixabay.com/photo/2017/09/14/11/06/water-2748638_960_720.png',
    thumbnail: 'https://cdn.pixabay.com/photo/2017/09/14/11/06/water-2748638_960_720.png',
  },
  {
    original: 'https://cdn.pixabay.com/photo/2016/11/25/07/00/diamond-1857736_960_720.png',
    thumbnail: 'https://cdn.pixabay.com/photo/2016/11/25/07/00/diamond-1857736_960_720.png',
  },
];

let pictures = [];

export default function ProductDetails(props) {
    const history = useHistory();
    
    let [values, setValues] = React.useState({
      productLoaded: false,
          reviewLoaded: false,
          relatedProductLoaded: false,
          details: [],
          related_products: [],
          reviews: [],
          count_reviews: 0,
          rating: 0,
          seller: [],
          comment: '',
          rating: '',
          product: props.match.params.product,
      });

    function onChangeHandler(event) {
        const { name, value } = event.target;
        setValues(values => ({...values, [name] : value}));
    }
    
    async function submitReview(event) {
        
        event.preventDefault();
        const status = document.getElementById('review-status');
        const btn = document.getElementById('review-btn');
          
        const { rating, comment, details, product } = values; 
        
        if(!rating || !comment || rating == "" || comment == "") {
            status.innerHTML = "<p style='color:red'> Ensure that both comment and the rating are done.</p>";
            return false;
        } else {
            
            Functions.disableBtn(btn);
            const data = `seller_id=${details.seller_id}&product_id=${product}&rating=${rating}&comment=${comment}`;
            
            await axios({
                method: 'post',
                url: `${serverEndPoint}customers/review/product`,
                data: data,
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept' : 'application/json',
                    'x-access-token' : customerCookie
                }
            })
            .then( async (response) => {
                Functions.enableBtn('Submit', btn);
                
                if(response.status == 201) {
                    status.innerHTML = `<p style='color:green'>Review added.</p>`;
                    document.getElementById('review-form').reset();
                    setValues( values => ({
                        ...values,
                        reviewLoaded: false,
                    }));
                    await getProductReviews();
                } else {
                    status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
                }
            })
            .catch( (error) => {
                console.log(error);
                Functions.enableBtn('Submit', btn);
                status.innerHTML = "<p style='color:red'> Ensure you are logged in to post a review. </p>";
            });
        }     
    }

    async function getProductDetails() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/details/${values.product}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200 && details) {  
				setValues( values => ({
					...values, 
                    details: details,
                    seller: details.seller,
                    productLoaded: true,
                }));
                
              for (let x = 0; x < details.pictures.length; x++) {
                  pictures[x] = {
                    original: details.pictures[x].path,
                    thumbnail: details.pictures[x].path,
                  };
                }
                console.log(pictures);
            } else {
                window.location="/404";
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }
    
    async function getProductReviews() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/reviews/${values.product}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            const details = response.data.data.reviews;
            const count_reviews = response.data.data.count_reviews;
            const rating = response.data.data.rating;
            
            if(response.status == 200) {  
				setValues( values => ({
					...values,
                    reviews: details,
                    count_reviews: count_reviews,
                    rating: rating,
                    reviewLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }
    
    async function getRelatedProducts() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/related/${values.product}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200) {  
				setValues( values => ({
					...values, 
                    related_products: details,
                    relatedProductLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
	}

	useEffect( () => {
        getProductDetails();
        getProductReviews();
		getRelatedProducts();
        document.title = webTitle+" Ads Details";
    }, []);

    return (
      <div className="page-wrapper">
        <Header />

        <div>
          <section
            className="detail-section bg-image"
            style={{
              backgroundImage: `url(${
                window.location.origin + "/assets/img/title-bg.jpg"
              })`,
            }}
            data-overlay="6"
          >
            <div className="container">
              <div className="title-content">
                {!values.productLoaded ? (
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <div style={{ fontSize: 25, marginTop: 0, lineHeight: 1 }}>
                    {" "}
                    {Functions.capitalizeWord(values.details.name)}{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="profile-cover-content">
              <div className="container">
                <div className="listing-owner">
                  {!values.seller.picture_url ? (
                    <div className="listing-owner-avater">
                      <img
                        src={window.location.origin + "/assets/img/avatar.png"}
                        className="img-responsive img-circle"
                        alt={values.seller.business}
                      />
                    </div>
                  ) : (
                    <div className="seller-avatar">
                      <img
                        src={values.seller.picture_url}
                        alt={values.seller.business}
                      />
                    </div>
                  )}

                  <div className="listing-owner-detail">
                    <h5 className="title-name">
                      {!values.productLoaded ? (
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        <Link to={`/seller/${values.seller.business_url}`}>
                          {values.seller.business}
                        </Link>
                      )}
                    </h5>
                    <span className="theme-cl">Seller</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="list-detail">
            <div className="">
              <div className="">
                <div className="col-md-8 col-sm-8 col-xs-12">
                  <div className="widget-boxed">
                    <div className="widget-boxed-header">
                      <h4>
                        <i className="ti-gallery padd-r-10"></i>Gallery
                      </h4>
                    </div>
                    <div className="widget-boxed-body">
                      <div className="side-list no-border gallery-box">
                        {!values.productLoaded ? (
                          <div align="center">
                            <div className="lds-ellipsis">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        ) : pictures.length > 0 ? (
                          <ImageGallery items={pictures} />
                        ) : (
                          <div
                            align="center"
                            style={{ fontSize: 20, color: "#EA7602" }}
                          >
                            {" "}
                            No pictures yet{" "}
                          </div>
                        )}
                        <br />
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </div>
                  </div>

                  <div className="detail-wrapper">
                    <div className="detail-wrapper-header">
                      <h4>Overview</h4>
                    </div>
                    <div className="detail-wrapper-body">
                      <div className="">
                        <div className="col-md-5 col-sm-5 col-xs-12">
                          {!values.productLoaded ? (
                            <div className="lds-ellipsis">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          ) : (
                            <span>
                              {" "}
                              Category -{" "}
                              {Functions.capitalizeWord(
                                values.details.category.name
                              )}{" "}
                              <p></p>
                              <br />
                            </span>
                          )}
                        </div>

                        <div className="col-md-3 col-sm-3 col-xs-12">
                          {!values.productLoaded ? (
                            <div className="lds-ellipsis">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          ) : (
                            <span>
                              Posted about {values.details.created_at} <p></p>
                              <br />
                            </span>
                          )}
                        </div>

                        <div className="col-md-3 col-sm-3 col-xs-12">
                          {!values.reviewLoaded ? (
                            <div className="lds-ellipsis">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          ) : (
                            <span>
                              <i className="fa fa-star"></i> {values.rating}{" "}
                              <br />
                            </span>
                          )}{" "}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="detail-wrapper">
                    <div className="detail-wrapper-header">
                      <h4>Ads Description</h4>
                    </div>
                    <div className="detail-wrapper-body">
                      <p>
                        {!values.productLoaded ? (
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        ) : (
                          <textarea
                            className="form-control"
                            rows="10"
                            style={{
                              textTransform: "none",
                              borderStyle: "none",
                              borderColor: "transparent",
                              overflow: "auto",
                              backgroundColor: "#fff",
                              resize: "none",
                            }}
                            value={values.details.description}
                          ></textarea>
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="detail-wrapper">
                    <div className="detail-wrapper-header">
                      <h4>
                        {!values.reviewLoaded ? (
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        ) : (
                          <div> {values.count_reviews} Reviews</div>
                        )}
                      </h4>
                    </div>

                    <div className="detail-wrapper-body">
                      {!values.reviewLoaded ? (
                        <div align="center" style={{ marginTop: 10 }}>
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {values.reviews.length == 0 ? (
                            <div
                              align="center"
                              style={{ fontSize: 25, color: "#EA7602" }}
                            >
                              {" "}
                              No reviews yet{" "}
                            </div>
                          ) : (
                            <div>
                              <ul className="review-list">
                                {values.reviews.map((review, index) => (
                                  <li key={index}>
                                    <div className="reviews-box">
                                      <div className="review-body">
                                        <div className="review-avatar">
                                          {!review.customer_picture ? (
                                            <img
                                              src={
                                                window.location.origin +
                                                "/assets/img/avatar.png"
                                              }
                                              className="avatar avatar-140 photo"
                                            />
                                          ) : (
                                            <img
                                              src={review.customer_picture}
                                              className=""
                                            />
                                          )}
                                        </div>
                                        <div className="review-content">
                                          <div className="review-info">
                                            <div className="review-comment">
                                              <div
                                                className="review-author"
                                                style={{ fontSize: 14 }}
                                              >
                                                {review.customer_fullname}
                                              </div>
                                              <div className="review-comment-stars">
                                                <i className="fa fa-star"></i>{" "}
                                                {review.rating}
                                              </div>
                                            </div>
                                            <div className="review-comment-date">
                                              <div className="review-date">
                                                <small>
                                                  {review.created_at}
                                                </small>
                                              </div>
                                            </div>
                                          </div>{" "}
                                          <br />
                                          <p style={{ fontSize: 13 }}>
                                            {review.comment}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="detail-wrapper">
                    <div className="detail-wrapper-header">
                      <h4>Rate Ads</h4>
                    </div>
                    <div className="">
                      <div className="">
                        <div className="col-md-12">
                          {/* <div id='rating' onClick={rate}>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div> */}

                          <label> Rating </label>
                          <select
                            className="form-control"
                            name="rating"
                            onChange={onChangeHandler}
                          >
                            <option value=""> -- Select an Option -- </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>

                      <div className="">
                        <form
                          method="post"
                          action="#"
                          onSubmit={(event) => submitReview(event)}
                          id="review-form"
                        >
                          <div className="col-sm-12">
                            <textarea
                              className="form-control height-110"
                              placeholder="Tell us your experience..."
                              name="comment"
                              onChange={onChangeHandler}
                            ></textarea>
                          </div>
                          <div className="col-sm-12">
                            <button
                              type="submit"
                              id="review-btn"
                              className="btn theme-btn"
                            >
                              Submit
                            </button>
                            <br />
                            <div id="review-status"></div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="sidebar">
                    <div className="widget-boxed">
                      <div className="widget-boxed-header">
                        <h4>
                          <i className="ti-check-box padd-r-10"></i>Related Ads
                        </h4>
                      </div>
                      <div className="widget-boxed-body padd-top-5">
                        <div className="side-list">
                          {!values.relatedProductLoaded ? (
                            <div align="center" style={{ marginTop: 10 }}>
                              <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {values.related_products.length == 0 ? (
                                <div
                                  align="center"
                                  style={{ fontSize: 25, color: "#EA7602" }}
                                >
                                  <br /> No related ads <br />
                                  <br />
                                  <br />
                                </div>
                              ) : (
                                <div>
                                  <ul>
                                    {values.related_products.map(
                                      (related_product, index) => (
                                        <TheRelatedProduct
                                          key={index}
                                          id={related_product.id}
                                          name={related_product.name}
                                          price={related_product.price}
                                          picture={related_product.picture}
                                          category={related_product.category}
                                          seller_institution={
                                            related_product.seller_institution
                                          }
                                          seller_phone={
                                            related_product.seller_phone
                                          }
                                          added_on={related_product.created_at}
                                        />
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div style={{ clear: "both" }}></div>

        <Footer />

        <a id="back2Top" className="theme-bg" title="Back to top" href="#">
          <i className="ti-arrow-up"></i>
        </a>
      </div>
    );
}
