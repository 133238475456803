import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from '../navs/Header';
import Footer from '../navs/Footer';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle } from '../../utils/Helpers';
import TheProduct from './TheProduct';

let paginations = [], current_page = 1;

export default function SortProducts(props) {
    
    const history = useHistory();
    
	let [values, setValues] = React.useState({
		productLoaded: false,
		showSort : false,
        showSearch: false,
        showCategories: false,
        showInstitutions: false,
        institutionsLoaded: false,
        sort_type: props.match.params.sort_type,
        search_result: '',
        search: '',
		products: [],
        categories: [],
        institutions: [],
        total_pages: 0,
        total_products: 0,
        current_page: 1,
        prev_page: 1,
        next_page: 1
    });
    
    async function getPageItems(page) {
        window.scrollTo(0,0);
        current_page = page;
            
        setValues(values => ({
            ...values, 
            productLoaded: false,
        }));

        await sortTheProducts(values.sort_type);
    }

    async function prevItem() {
        window.scrollTo(0,0);
        
        let prev_page = values.current_page-=1;
        
        setValues(values => ({
            ...values, 
            current_page: prev_page,
            productLoaded: false,
        }));

        await sortTheProducts(values.sort_type);
    }

    async function nextItem() {

        window.scrollTo(0,0);
        
        let prev_page = values.current_page;
        let next_page = values.current_page+=1;
        
        setValues(values => ({
            ...values, 
            current_page: next_page,
            prev_page: prev_page,
            productLoaded: false,
        }));

        await sortTheProducts(values.sort_type);
    }
    
    function onChangeHandler(event) {
        const { name, value } = event.target;
        setValues(values => ({...values, [name] : value}));
    }

    async function selectOperation(event) {
		
		const { name, value } = event.target;
        
		if(value == "search") {
            setValues(values => ({
                ...values, 
                showSearch : true,
                showSort: false,
                showCategories: false,
                showInstitutions: false,
            }));
		} else if(value == "sort") {
            setValues(values => ({
                ...values, 
                showSort : true,
                showSearch: false,
                showCategories: false,
                showInstitutions: false,
             }));
		} else {
            history.replace(`/ads/sort/${value}`);
		}
    }
    
    async function searchProduct(event) {
    
        event.preventDefault();
        const status = document.getElementById('search-status');
        const { search } = values;
		
		if(!search || search == "") {
            status.innerHTML = "<p style='color:red'> No match found </p>";
            return false;
        } else {
            history.replace(`/ads/search?institution=all&q=${search}`);
		}     
    }

	async function sortProducts(event) {
		
		const { name, value } = event.target;
        
		if(value == "category") {
            setValues(values => ({
                ...values, 
                showCategories : true,
                showInstitutions : false
            }));
		} else if(value == "institution") {
            setValues(values => ({
                ...values, 
                showInstitutions : true,
                showCategories : false
            }));
		} else {
            window.location = `/ads/sort/${value}`;
        }
	}

	async function selectCategory(event) {
		const { name, value } = event.target;
        history.replace(`/ads/category/${value}`);
	}

    async function selectInstitution(event) {
		const { name, value } = event.target;
	    history.replace(`/ads/institution/${value}`);
	}
	
	async function sortTheProducts(sort_type) {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/sort/${sort_type}/none/${current_page}`,
        }).then( async (response) => {
            
            const details = response.data.data;
            const total_products = response.data.data.count_products;
            const total_pages = response.data.data.total_pages;

            for(let i = 1; i <= total_pages; i++) {
                paginations[i] = i;    
            }
            
            setValues( values => ({
                ...values, 
                productLoaded: true,
                products: details.sort_result.result,
                total_pages: total_pages,
                total_products: total_products,
                search_result: details.sort_result.title,
            }));

        });	
    }

	async function getCategories() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/categories`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200) {  
				setValues( values => ({
					...values, 
					categories: details,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }
    
    async function getInstitutions() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`all-institutions`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200) {  
                setValues( values => ({
                    ...values, 
                    institutions: details,
                    institutionsLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            console.log(error);
        });
	}

	useEffect( () => {
        sortTheProducts(values.sort_type);
        getCategories();
        getInstitutions();
    	document.title = webTitle+" Ads";
    }, []);

    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}}>
				<div className="container">
					<div className="title-content">
                        <h3 style={{color: '#fff'}}>{Functions.capitalizeWord(values.search_result)}</h3>
					</div>
				</div>s
			</section> 
			<div className="clearfix"></div>
            
            <section className="padd-top-0 padd-bot-0 overlap">
                <div className="container">
                    <div className="">
                        <div className="col-md-12 col-sm-12">
                            <div className="white-box white-shadow padd-top-30 padd-bot-30 translateY-60">
                                
                                <div className="col-md-4 col-sm-4">
                                    <select className="form-control" name="operation" onChange={event => selectOperation(event)}>
                                        <option value=""> -- What do you want to do? -- </option>
                                        <option value="search">Search Ads</option>
                                        <option value="sort">Sort Ads</option>
                                    </select>
                                </div>

                                {values.showSort ?
                                    <div className="col-md-4 col-sm-4">
                                        <select className="form-control" name="sort" onChange={event => sortProducts(event)}>
                                            <option value=""> -- Select an option -- </option>
                                            <option value="recent">Recent Ads</option>
                                            <option value="oldest">Oldest Ads</option>
                                            <option value="alphabetically">Alphabetically</option>
                                            <option value="low-high-price">Low-to-high price</option>
                                            <option value="high-low-price">High-to-low price</option>
                                            <option value="category">By Categories</option>
                                            <option value="institution">By Institutions</option>
                                        </select>
                                    </div> : null
                                }

                                {values.showCategories ?
                                    <div className="col-md-4 col-sm-4">
                                        <select className="form-control" name="sort" onChange={event => selectCategory(event)}>
                                            <option value=""> -- Select category -- </option>
                                                {values.categories.map( (category, index) => 
                                                    <option key={index} value={category.id}>
                                                        {Functions.capitalizeWord(category.name)} ({category.type})
                                                    </option>
                                                ) 
                                                }
                                        </select>
                                    </div> :  null										
                                }

                                {values.showInstitutions ?
                                    <div className="col-md-4 col-sm-4">
                                        {values.institutionsLoaded?
                                            <select className="form-control" name="sort" onChange={event => selectInstitution(event)}>
                                                <option value=""> -- Select institution -- </option>
                                                    {values.institutions.map( (institution, index) => 
                                                        <option key={index} value={institution.id}>
                                                            {Functions.capitalizeWord(institution.name)}
                                                        </option>
                                                    ) 
                                                    }
                                            </select> : 
                                            <p style={{color: '#000'}}> Please wait while institutions are retrieved... </p>
                                        }
                                    </div> :  null										
                                }
                                
                                {values.showSearch ?
                                    <div className="col-md-8 col-sm-8">
                                        <form method="post" action="#" onSubmit={event => searchProduct(event)}>
                                            <div className="col-md-10 col-sm-10">
                                                <input type="text" name="search" onChange={onChangeHandler} className="form-control" placeholder="What are you looking for?"/>
                                            </div>
                                            <div className="col-md-2 col-sm-2">
                                                <button type="submit" id="search-btn" className="btn theme-btn normal-height full-width"><i className="fa fa-search"></i></button>
                                            </div>
                                        </form>
                                        <div style={{clear: 'both'}}></div>
                                        <div className="container" id="search-status" style={{color:'red'}}></div>
                                    </div> : null
                                }
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

			<section className="padd-top-20" style={{marginTop:-50}}>

                <div>
                    
                    {!values.productLoaded?
                        
                        <div align="center" style={{marginTop:30}}>
                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> <br/><br/>
                        </div> :
                        
                        <div style={{marginLeft:20, marginRight: 20, marginTop:10}}>

                            {values.total_products == 0 ?
                                
                                <div align="center" style={{marginTop:50, fontSize:40, color: '#EA7602'}}>
                                    <i className="fa fa-bullhorn"></i> <br/><br/>
                                    No Ads Yet<br/><br/>
                                </div> :
                                
                                <div>
                                    <ul>
                                        
                                        {values.products.map( (product, index) => 
                                            <TheProduct
                                                key = {index}
                                                from = 'home'
                                                id = {product.id}
                                                name = {product.name}
                                                price = {product.price}
                                                picture = {product.picture}
                                                category = {product.category}
                                                seller_institution = {product.seller_institution}
                                                seller_phone = {product.seller_phone}
                                                added_on = {product.created_at}
                                                rating = {product.rating}
                                            />
                                        )}

                                    </ul>
                                            
                                    <div align="center">
                                        <p style={{textTransform: 'none'}}> Page <b>{current_page}</b> of {values.total_pages} </p>

                                        {values.total_pages > 1 ?
                                            (paginations.map( (page, index) => {
                                                return <button onClick={getPageItems.bind(this, page)} style={{borderRadius: 5}} className="btn btn-xs btn-info">{page}</button>
                                            })) : null
                                        }
                                    </div>

                                </div> 
                            }

                        </div>
                    }
                    <div style={{clear: 'both'}}></div>
                </div>
                
        	</section>

			<Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
