import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../navs/Header';
import Footer from '../navs/Footer';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle, customerCookie} from '../../utils/Helpers';

export default function CustomerEditProfile() {
    
    let [institutions, setInstitutions] = React.useState('');

    let [values, setValues] = React.useState({
        firstname: '', 
        lastname: '',
        phone: '',
        picture: '',
        about: '',
        address: '',
        state: '',
        city: '',
        institution: '',
        institution_name: '',
        dob: '',
    });
    
    function onChangeHandler(event) {
        const { name, value } = event.target;
        setValues(values => ({...values, [name] : value}));
    }

    async function uploadPhoto(event) {        
        let formData = new FormData();
        const files = event.target.files;
        formData.append(`photo`, files[0]);

        const status = document.getElementById('upload-picture-status');
        status.innerHTML = 'Uploading ...';

        await axios({
            method: 'post',
            url: `${serverEndPoint}sellers/update/picture`,
            data: formData,
            headers : {
                'Content-Type': 'application/form-data',
                'Accept' : 'application/json',
                'x-access-token' : customerCookie
            }
        })
        .then(function (response) {
            
            if(response.data.data.type == "success") {
                status.innerHTML = `<p style='color:green'>Profile picture uploaded.</p>`;
                window.location = `/customers/profile`;
            } else {
                status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
            }
        })
        .catch(function (response) {
            status.innerHTML = `<p style='color:red'>Profile picture not uploaded. Try again</p>`;
        });
    }
    
    async function getCustomerDetails() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+'customers/profile',
            headers : {
                'Content-Type': 'application/json',
                'x-access-token' : customerCookie
            }
        })
        .then( async (response) => {
            
            if(response.status) {
                let details = await response.data.data;
                
                setValues( values => ({
                    ...values,
                    firstname : details.firstname,
                    lastname : details.lastname,
                    email : details.email,
                    phone : details.phone,
                    institution : details.institution_id,
                    institution_name: details.institution_name,
                    dob : details.dateofbirth,
                    state : details.state,
                    city : details.city,
                    about : details.about,
                    address : details.address,
                    picture : details.picture_url,
                    profileLoaded: true,
                }));

                //get other institutions

                await axios({
                    method: 'get',
                    url: serverEndPoint+`institutions/others/${details.institution}`,
                    headers : {
                        'Content-Type' : 'application/json',
                        'Accept' : 'application/json',
                    }
                })
                .then( async (response) => {
                    let details = response.data.data.others;
                    
                    if(response.status == 200) {  
                        setInstitutions(institutions => [...institutions, details]);
                        setValues( values => ({
                            ...values, 
                            loaded: true,
                        }));
                    }
                }).
                catch( (error) => {
                    console.log(error);
                });
            }
        }).
        catch( (error) => {
            //window.location = "/login";
        });
    }


    async function updateProfile(event) {
        event.preventDefault();
        const status = document.getElementById('update-profile-status');
        const btn = document.getElementById('update-profile-btn');
          
        const { firstname, lastname, phone, dob, state, city, address, institution, about } = values;        
        
        if(dob == undefined || !dob) {
            status.innerHTML = "<p style='color:red'> Select your date of birth </p>";
            return false;
        }

        const allValues = [firstname, lastname, phone, dob, state, city, address, institution];
        const isValueEmpty = Functions.isEmpty(allValues);

        if(isValueEmpty) {
            status.innerHTML = "<p style='color:red'> Fill all fields </p>";
            return false;
        } else {
            
            Functions.disableBtn(btn);
            const data = `firstname=${firstname}&lastname=${lastname}&institution=${institution}&phone=${phone}&dob=${dob}&state=${state}&city=${city}&address=${address}&about=${about}`; 
            
            await axios({
                method: 'put',
                url: `${serverEndPoint}customers/update/profile`,
                data: data,
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept' : 'application/json',
                    'x-access-token' : customerCookie
                }
            })
            .then(response => {
                Functions.enableBtn('Save changes!', btn);
                
                if(response.data.data.type == "success") {
                    status.innerHTML = `<p style='color:green'>Profile successfully updated.</p>`;
                    window.location = "/customers/profile";
                } else {
                    status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
                }
            }).
            catch( (error) => {
                Functions.enableBtn('Save changes!', btn);
                status.innerHTML = "<p style='color:red'> Profile not updated.Try again. </p>";
            });
        }     
    }

    useEffect( () => {
        getCustomerDetails();
    }, []);
    
    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}} >
				<div className="container">
					<div className="title-content">
						<h1>Edit Profile</h1>
					</div><br/><br/>
				</div>
			</section>
        
            <section className="padd-0">
				<div className="container">
					<div className="add-listing-box translateY-60 edit-info mrg-bot-25 padd-bot-30 padd-top-25">
						
                        <form method="post" action="#" onSubmit={event => updateProfile(event)}>
                            <div className="">

                                <div className="listing-box-header">
                                    {values.picture ? 
                                        
                                        <div>
                                            <img src={values.picture} className="avater-box" alt=""/>
                                        </div> : 

                                        <div className="avater-box">
                                            <img alt="" src={window.location.origin + '/assets/img/avatar.png'} className="img-responsive img-circle"/>
                                        </div>
                                    }

                                    <div align="center">
                                        <input type="file" name="photo" accept='.jpg, .png. .jpg, .PNG, .JPG, .JPEG' onChange={uploadPhoto}/>
                                        <div id="upload-picture-status"></div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-6">
                                    <label>Firstname</label>
                                    <input type="text" className="form-control" name="firstname" onChange={onChangeHandler} value={values.firstname}/>
                                </div>
                                <div className="col-sm-6">
                                    <label>Lastname</label>
                                    <input type="text" className="form-control" name="lastname" onChange={onChangeHandler} value={values.lastname}/>
                                </div>
                                <div className="col-sm-6">
                                    <label>Phone</label>
                                    <input type="text" className="form-control" name="phone" onChange={onChangeHandler} value={values.phone}/>
                                </div>
                                <div className="col-sm-6">
                                    <label>Date of birth</label>
                                    <input type="date" className="datedropper form-control"  name="dob" onChange={onChangeHandler} value={values.dob}/>
                                </div>
                                <div className="col-sm-6">
                                    <label>State</label>
                                    <input type="text" className="form-control" name="state" onChange={onChangeHandler} value={values.state}/>
                                </div>
                                <div className="col-sm-6">
                                    <label>City</label>
                                    <input type="text" className="form-control" name="city" onChange={onChangeHandler} value={values.city}/>
                                </div>
                                <div className="col-sm-6">
                                    <label>Address</label>
                                    <textarea className="form-control" name="address" onChange={onChangeHandler} value={values.address}></textarea>
                                </div>
                                <div className="col-sm-6">
                                    <label>Institution</label>
                                    <select className="form-control" name="institution" onChange={onChangeHandler}>
                                        <option value={values.institution}>{values.institution_name}</option>
                                        {values.loaded?
                                            institutions[0].map( (institution, index) => 
                                                <option key={index} value={institution.id}>
                                                    {institution.name}
                                                </option>
                                            ) : null
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-12 col-md-12 col-xs-12">
                                    <label>About me</label>
                                    <textarea className="form-control" rows="6" name="about" onChange={onChangeHandler} value={values.about}></textarea>
                                </div>
                            </div> <br/>
                            <div className="text-center">
                                <button type="submit" id="update-profile-btn" className="btn btn-warning"><i className="fa fa-check"></i> Save changes </button>
                                <br/><br/>
                                <div id="update-profile-status"></div>
                            </div>
                        </form>
					</div>
				</div>
			</section>
			
            <Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
