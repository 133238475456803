import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Functions from './utils/Functions';
import ErrorBoundary from './utils/ErrorBoundary';

//General
import Error404 from './components/Error404';
import Home from './components/Home';
import Login from './components/accounts/Login';
import Register from './components/accounts/Register';
import ForgotPassword from './components/accounts/ForgotPassword';
import ResetPassword from './components/accounts/ResetPassword';
import ActivatePasswordReset from './components/accounts/ActivatePasswordReset';
import ActivateAccount from './components/accounts/ActivateAccount';
import Products from './components/products/Products';
import ProductDetails from './components/products/ProductDetails';
import SellerDetails from './components/products/SellerDetails';
import SearchProducts from './components/products/SearchProducts';
import SortProducts from './components/products/SortProducts';
import CategoryProducts from './components/products/CategoryProducts';
import InstitutionProducts from './components/products/InstitutionProducts';
import Contact from './components/Contact';
import Faq from './components/Faq';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';

//customers
import CustomerProfile from './components/customers/Profile';
import CustomerEditProfile from './components/customers/EditProfile';
import CustomerChangePassword from './components/customers/ChangePassword';

//sellers
import AddProduct from './components/sellers/products/AddProduct';
import AddProductPictures from './components/sellers/products/AddProductPictures';
import EditProduct from './components/sellers/products/EditProduct';
import MyProducts from './components/sellers/products/Products';
import MyProductDetails from './components/sellers/products/ProductDetails';
import SearchMyProducts from './components/sellers/products/SearchProducts';
import SortMyProducts from './components/sellers/products/SortProducts';
import MyCategoryProducts from './components/sellers/products/CategoryProducts';

import SellerProfile from './components/sellers/Profile';
import SellerEditProfile from './components/sellers/EditProfile';
import SellerChangePassword from './components/sellers/ChangePassword';

export default () => {
  
  const customer_cookie = Functions.getCookie('bineza_customer_token');
  const seller_cookie = Functions.getCookie('bineza_seller_token');

  return (
    <div>
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/404" component={Error404} />
            <Route path="/login" component={Login}/>
            <Route path="/register" component={Register}/>
            <Route path="/forgot-password" component={ForgotPassword}/>
            <Route path="/activate-account/:code" component={ActivateAccount}/>
            <Route path="/activate-password-reset/:code" component={ActivatePasswordReset}/>
            <Route path="/reset-password/:code" component={ResetPassword}/>
            <Route path="/ads/search" component={SearchProducts}/>
            <Route path="/ads/sort/:sort_type" component={SortProducts}/>
            <Route path="/ads/category/:category" component={CategoryProducts}/>
            <Route path="/ads/institution/:institution" component={InstitutionProducts}/>
            <Route path="/ads/details/:product" component={ProductDetails}/>
            <Route path="/ads" component={Products}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/faq" component={Faq}/>
            <Route path="/terms-and-conditions" component={TermsAndConditions}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/customers/profile" component={CustomerProfile}/>
            <Route path="/customers/edit-profile" component={CustomerEditProfile}/>
            <Route path="/customers/change-password" component={CustomerChangePassword}/>
            <Route path="/sellers/profile" component={SellerProfile}/>
            <Route path="/sellers/edit-profile" component={SellerEditProfile}/>
            <Route path="/sellers/change-password" component={SellerChangePassword}/>
            <Route path="/sellers/ads/search" component={SearchMyProducts}/>
            <Route path="/sellers/ads/sort/:sort_type" component={SortMyProducts}/>
            <Route path="/sellers/ads/category/:category" component={MyCategoryProducts}/>
            <Route path="/sellers/ads/details/:product" component={MyProductDetails}/>
            <Route path="/sellers/ads/add/pictures/:product" component={AddProductPictures}/>
            <Route path="/sellers/ads/add" component={AddProduct}/>
            <Route path="/sellers/ads/edit/:product_id" component={EditProduct}/>
            <Route path="/seller/:business_url" component={SellerDetails}/>
            <Route path="/sellers/ads" component={MyProducts}/>
          </Switch> 
        </Router> 
      </ErrorBoundary>
    </div>
  );
}



