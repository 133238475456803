import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import Functions from '../../utils/Functions';
import { serverEndPoint, webTitle, customerCookie, sellerCookie} from '../../utils/Helpers';

export default function Header() {
    
    let [values, setValues] = React.useState({
        user: '',
        user_type: '',
    });

    function logout() {
        document.getElementById('navbar').click();
        swal({
            title: "",
            text: "Are you sure you want to logout?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then( async (response) => {
            if (response) {
                if(values.user_type == 'customer') {
                    await Functions.deleteCookie('bineza_customer_token');
                } else if(values.user_type == 'seller') {
                    await Functions.deleteCookie('bineza_seller_token');
                }
                window.location = "/login";
            }
        });
    }

    async function updateUserStatus() {
        
        window.scrollTo(0,0);
        
        if(customerCookie) {
            setValues(values => ({
                ...values, 
                user: customerCookie,
                user_type: 'customer'
            }));

            await axios({
                method: 'put',
                url: serverEndPoint+'customers/update/status/online',
                headers : {
                    'Content-Type': 'application/json',
                    'x-access-token' : customerCookie
                }
            })
            .then( async (response) => {})
            .catch( (error) => {});

        } else if(sellerCookie) {
            setValues(values => ({
                ...values, 
                user : sellerCookie,
                user_type: 'seller'
            }));

            await axios({
                method: 'put',
                url: serverEndPoint+'sellers/update/status/online',
                headers : {
                    'Content-Type': 'application/json',
                    'x-access-token' : sellerCookie
                }
            })
            .then( async (response) => {})
            .catch( (error) => {});

        } else {
            setValues(values => ({
                ...values, 
                user: 'none',
                user_type: 'none'
            }));
        }
    }

    useEffect( () => {
        updateUserStatus();
    }, []);

    return (

        <nav className="navbar navbar-default navbar-fixed navbar-transparent white bootsnav">
            <div className="container-fluid">          
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu" id="navbar">
                    <i className="ti-align-left"></i>
                </button>
                
                <div className="" align="center">
                    <Link to="/" className="navbar-brand">
                        <img src={window.location.origin + '/assets/img/logo-white.png'} style={{maxHeight: '180px', marginTop: -70, marginLeft:20}} className="logo logo-display" alt=""/>
                        <img src={window.location.origin + '/assets/img/logo-white.png'} style={{maxHeight: '180px', marginTop: -70, marginLeft:20}} className="logo logo-scrolled" alt=""/>
                    </Link>
                </div>
               
                {values.user == '' ?
                
                    null :

                    (values.user == 'none' ?
                    
                        <div className="collapse navbar-collapse" id="navbar-menu">
                            <ul className="nav navbar-nav centered-div" data-in="fadeInDown" data-out="fadeOutUp">
                                <li className="centered-div"><Link to="/">Home</Link></li>
                                <li className="centered-div"><Link to="/ads">Ads</Link></li>
                                <li className="centered-div"><Link to="/register">Sign Up</Link></li>
                                <li className="centered-div"><Link to="/login">Sign In</Link></li> 
                                <li className="centered-div"><Link to="/contact">Contact us</Link></li>
                            </ul> 
                        </div> : 

                        (values.user_type == 'customer' ?
                            
                            <div className="collapse navbar-collapse" id="navbar-menu">
                                <ul className="nav navbar-nav centered-div" data-in="fadeInDown" data-out="fadeOutUp">
                                    <li className="centered-div"><Link to="/">Home</Link></li>
                                    <li className="centered-div"><Link to="/ads">Ads</Link></li>
                                    <li className="dropdown">
                                        <Link to="#" className="dropdown-toggle" data-toggle="dropdown">Settings</Link>
                                        <ul className="dropdown-menu animated">
                                            <li><Link to="/customers/profile">My Profile</Link></li>
                                            <li><Link to="/customers/edit-profile">Edit Profile</Link></li>
                                            <li><Link to="/customers/change-password">Change Password</Link></li>
                                        </ul>
                                    </li>
                                    <li className="centered-div"><Link to="#" onClick={logout}>Logout</Link></li>
                                    <li className="centered-div"><Link to="/contact">Contact us</Link></li>
                                </ul>
                            </div> :

                            <div className="collapse navbar-collapse" id="navbar-menu">
                                <ul className="nav navbar-nav centered-div" data-in="fadeInDown" data-out="fadeOutUp">
                                    <li className="centered-div"><Link to="/">Home</Link></li>
                                    <li className="centered-div"><a href="/sellers/ads">My Ads</a></li>
                                    <li className="dropdown">
                                        <Link to="#" className="dropdown-toggle" data-toggle="dropdown">Settings</Link>
                                        <ul className="dropdown-menu animated">
                                            <li><a href="/sellers/profile">My Profile</a></li>
                                            <li><Link to="/sellers/edit-profile">Edit Profile</Link></li>
                                            <li><Link to="/sellers/change-password">Change Password</Link></li>
                                        </ul>
                                    </li>
                                    <li className="centered-div"><Link to="#" onClick={logout}>Logout</Link></li>
                                    <li className="centered-div"><Link to="/contact">Contact us</Link></li>
                                </ul>
                            </div>
                        )
                    )
                }
            </div>   
        </nav>
    );
}
