import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import { serverEndPoint, sellerCookie } from '../../utils/Helpers';

export default function SellerHooks() {
    
    let [data, setData] = React.useState({
        firstname: '', 
        lastname: '',
        email: '',
        phone: '',
        picture: '',
        about: '',
        address: '',
        state: '',
        city: '',
        institution: '',
        business: '',
        business_url: '',
        started_at: '',
        institution_name: '',
        dob: '',
    });

    async function getSellerDetails() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+'sellers/profile',
            headers : {
                'Content-Type': 'application/json',
                'x-access-token' : sellerCookie
            }
        })
        .then( async (response) => {
            
            if(response.status) {
                let details = await response.data.data;
                
                setData( data => ({
                    ...data,
                    firstname : details.firstname,
                    lastname : details.lastname,
                    email : details.email,
                    phone : details.phone,
                    business : details.business,
                    business_url : `https://www.bineza.com/seller/${details.business_url}`,
                    started_at : details.started_at,
                    institution : details.institution_id,
                    institution_name : details.institution_name,
                    dob : details.dob,
                    state : details.state,
                    city : details.city,
                    address : details.address,
                    about : details.about,
                    picture: details.picture_url,
                    profileLoaded: true,
                }));
            }
        }).
        catch( (error) => {
            //window.location = "/login";
        });
    }

    useEffect( () => {
        getSellerDetails();
    }, []);
    
    return data;
}
