import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../navs/Header';
import Footer from '../../navs/Footer';
import Functions from '../../../utils/Functions';
import { serverEndPoint, webTitle, sellerCookie} from '../../../utils/Helpers';

export default function EditProduct(props) {
    
    let [ad_types, setTypes] = React.useState(['goods', 'services']);
    let [pricing_types, setPricingTypes] = React.useState(['fixed', 'varies']);

    let [values, setValues] = React.useState({
        product_id: props.match.params.product_id,
        category_id: '',
        category_name: '',
        name: '', 
        type: '',
        the_type: '',
        the_pricing_type: '',
        pricing_type: '',
        fixed_price: '',
        varying_price: '',
        description: '',
        categories: [],
        categoryLoaded: '',
        productLoaded: [],
    });
    
    function onChangeHandler(event) {
        const { name, value } = event.target;
        
        if(name == 'pricing_type') {
            setValues(values => ({...values, [name] : value}));
            
            if(value == 'fixed') {
                document.getElementById('varying_price').value = '';
            } else if(value == 'varies') {
                document.getElementById('fixed_price').value = '';
            } else {
                document.getElementById('fixed_price').value = '';
                document.getElementById('varying_price').value = '';
            }

        } else {
            
            if(name == 'fixed_price') {
                if(Functions.isInt(value)) {
                    setValues(values => ({
                        ...values, 
                        fixed_price : value,
                        varying_price: '',
                    }));
                } else {
                    document.getElementById('fixed_price').value = '';
                    setValues(values => ({
                        ...values, 
                        fixed_price : '',
                        varying_price: '',
                    }));
                }
            } else if(name == 'varying_price') {
                setValues(values => ({
                    ...values, 
                    fixed_price : '',
                    varying_price: value,
                }));
            } else {
                setValues(values => ({...values, [name] : value}));
            }
        }
    }

    async function getProductDetails() {
        
        await axios({
            method: 'get',
            url: serverEndPoint+`products/details/${values.product_id}`,
            headers : {
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
            }
        })
        .then( async (response) => {
            let details = response.data.data;
            
            if(response.status == 200) {
                
                let pricing_type, fixed_price, varying_price;

                if(Functions.isInt(details.price)) {
                    pricing_type = 'fixed';
                    fixed_price = details.price;
                    varying_price = '';
                } else {
                    pricing_type = 'varies';
                    varying_price = details.price;
                    fixed_price = '';
                }

				setValues( values => ({
					...values, 
                    category_id: details.category_id,
                    name: details.name,
                    the_type: details.type,
                    type: details.type,
                    the_pricing_type: pricing_type,
                    pricing_type: pricing_type,
                    fixed_price: fixed_price,
                    varying_price: varying_price,
                    description: details.description,
                    productLoaded: true,
                }));

                //get other categories 
                
                await axios({
                    method: 'get',
                    url: serverEndPoint+`products/categories/others/${details.category_id}`,
                    headers : {
                        'Content-Type' : 'application/json',
                        'Accept' : 'application/json',
                    }
                })
                .then( async (response) => {
                    let details = response.data.data;
                    
                    if(response.status == 200) {  
                        setValues( values => ({
                            ...values, 
                            category_name: details.current.name,
                            categories: details.others,
                            categoryLoaded: true,
                        }));
                    }
                }).
                catch( (error) => {
                    console.log(error);
                });
            }
        }).
        catch( (error) => {
            console.log(error);
        });
    }

    async function updateProduct(event) {
        event.preventDefault();
        const status = document.getElementById('update-product-status');
        const btn = document.getElementById('update-product-btn');
        
        const { product_id, category_id, name, pricing_type, fixed_price, varying_price, type, description } = values;  
        const allValues = [product_id, category_id, name, pricing_type, type, description];
        const isValueEmpty = Functions.isEmpty(allValues);
        let actual_price;

        if(pricing_type == 'fixed' && fixed_price != '') {
            if(Functions.isInt(fixed_price)) {
                actual_price = fixed_price;
            } else {
                actual_price = '';
            }
        } else if(pricing_type == 'varies' && varying_price != '') {
            actual_price = varying_price;
        } else {
            actual_price = '';
        }

        if(isValueEmpty) {
            status.innerHTML = "<p style='color:red'> Fill all fields </p>";
            return false;
        } else if(actual_price == '') {
            status.innerHTML = "<p style='color:red'> Enter the price </p>";
            return false;
        } else {
            
            Functions.disableBtn(btn);

            //const data = `category_id=${category_id}&name=${name}&price=${actual_price}&type=${type}&description=${description}`; 
            
            const data = `product_id=${product_id}&category_id=${category_id}&name=${name}&price=${actual_price}&type=${type}&description=${description}`; 
            
            await axios({
                method: 'put',
                url: `${serverEndPoint}sellers/product/update`,
                data: data,
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept' : 'application/json',
                    'x-access-token' : sellerCookie
                }
            })
            .then(response => {
                Functions.enableBtn('Save changes', btn);
                
                if(response.data.data.type == "success") {
                    status.innerHTML = `<p style='color:green'>Ads successfully updated.</p>`;
                   window.location = "/sellers/ads";
                } else {
                    status.innerHTML = `<p style='color:red'>${response.data.data.message}</p>`;
                }
            }).
            catch( (error) => {
                Functions.enableBtn('Save changes', btn);
                status.innerHTML = "<p style='color:red'> Ads not updated. Try again. </p>";
            });
        }     
    }

    useEffect( () => {
        getProductDetails();
        document.title = webTitle+` Edit Ads`;
    }, []);
    
    return (

        <div className="page-wrapper"> 
      
            <Header/>

            <div className="clearfix"></div>
			<section className="title-transparent product-title" style={{backgroundImage: `url(${window.location.origin + ('/assets/img/title-bg.jpg')})`}}>
				<div className="container">
					<div className="title-content">
						<h1>Update {values.name} </h1>
					</div>
				</div> <br/><br/>
			</section>
        
            <section className="padd-0">
				<div className="container">
					<div className="add-listing-box translateY-60 edit-info mrg-bot-25 padd-bot-30 padd-top-25">
						
                        <form method="post" action="#" onSubmit={event => updateProduct(event)}>
                            <div className="">
                                
                                <div className="col-sm-6">
                                    <label>Category</label>
                                    {values.categoryLoaded ?
                                        <select className="form-control" name="category_id" onChange={onChangeHandler}>
                                            <option value={values.category_id}>{Functions.capitalizeWord(values.category_name)}</option>
                                                {values.categories.map( (category, index) => 
                                                    <option key={index} value={category.id}>
                                                        {Functions.capitalizeWord(category.name)}
                                                    </option>
                                                ) 
                                                }
                                        </select> :  
                                        <div> <br/>
                                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        </div>										
                                    }
                                </div>

                                <div className="col-sm-6">
                                    <label>Type</label>
                                    <select className="form-control" name="type" onChange={onChangeHandler}>
                                        <option value={values.the_type}>{Functions.capitalizeWord(values.the_type)}</option>
                                        
                                        {ad_types.map( (ad_type, ad_index) => 
                                            (ad_type != values.the_type ?
                                                <option key={ad_index} value={ad_type}>
                                                    {Functions.capitalizeWord(ad_type)}
                                                </option> : null
                                            )
                                        )}

                                    </select>
                                </div>
                                
                                <div className="col-sm-12">
                                    <label>Title </label>
                                    <input type="text" className="form-control" name="name" onChange={onChangeHandler} value={values.name}/> 
                                </div>

                                <div className="col-sm-6">
                                    <label>Pricing Type</label>
                                    <select className="form-control" name="pricing_type" onChange={onChangeHandler}>
                                        <option value={values.the_pricing_type}>{Functions.capitalizeWord(values.the_pricing_type)}</option>
                                        
                                        {pricing_types.map( (pricing_type, pricing_index) => 
                                            (pricing_type != values.the_pricing_type ?
                                                <option key={pricing_index} value={pricing_type}>
                                                    {Functions.capitalizeWord(pricing_type)}
                                                </option> : null
                                            )
                                        )}
                                    </select> 
                                </div>
                                                
                                <div className="col-sm-6">
                                    
                                    <label>Price </label>
                                    
                                    {values.pricing_type == 'fixed'?
                                        
                                        <div>
                                            <input type="number" className="form-control" name="fixed_price" id="fixed_price" onChange={onChangeHandler} onKeyPress={Functions.isCharNumber} inputmode="numeric" pattern="[0-9]*" value={values.fixed_price} maxLength="15"/>
                                        </div> :
                                        
                                        <div>
                                            <input type="text" className="form-control" name="varying_price" id="varying_price" onChange={onChangeHandler} value={values.varying_price} maxLength="15"/> <br/>
                                        </div>
                                    }
    
                                </div>

                                <div className="col-sm-12 col-xs-12 col-md-12">
                                    <label>Description</label>
                                    <textarea className="form-control" rows="10" name="description" onChange={onChangeHandler} value={values.description}></textarea>
                                </div>

                            </div> <br/>

                            <div style={{clear: 'both'}}></div>
                            <br/>

                            <div className="text-center">
                                <button type="submit" id="update-product-btn" className="btn btn-warning"><i className="fa fa-check"></i> Save changes </button>
                                <br/><br/>
                                <div id="update-product-status"></div>
                            </div>
                        </form>
					</div>
				</div>
			</section>
			
            <Footer/>

            <a id="back2Top" className="theme-bg" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        </div>
    );
}
